import React, { useEffect, useState, useRef } from "react";
import Table_profile from "../common/Table_profile";
import { Block, Cancel, Circle, Delete, EmptyData, Eye, LeftIcon, Ok, RightIcon, Search } from "../common/Icons";
import Search_input from "../common/Search_input";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
import server from "../server";
import { toast } from "react-toastify";
import moment from "moment";
import { getRequest, postRequest } from "../ApiFunction";
import API, { ImageUrl } from "../Api"
import Loader from "../common/Loader";

export default function Provider_management() {
  const nav = useNavigate();
  const [load, setLoad] = useState(true)
  const [skip, setSkip] = useState(0);
  const [totalDoc, setTotalDoc] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNum, setPageNum] = useState(1)
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState();
  const [user_id, setId] = useState("");



  const BlockStatus = async (status, id) => {
    setId(id);
    setStatus(status)
  };


  const verify = async (id) => {
    setId(id);
  };


  const DeleteUser = async (id) => {
    setId(id);
  };


  const getAll = async () => {
    try {
      const result = await getRequest(`${API.GET_ALL_PROVIDER}?search=${search}&skip=${skip}&limit=${limit}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        setUsers(result.data.data);
        setTotalDoc(result.data.totalDocuments);
        setLoad(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  }


  const searchType = async (e) => {
    setSearch(e.target.value);
  };



  const adminBlockUser = async (id) => {
    try {
      const result = await postRequest(`${API.BLOCK_CUSTOMER}?userId=${id}`)
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        getAll();
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  };

  const adminVerifyUser = async () => {
    try {
      const result = await postRequest(`${API.ADMIN_VERIFY_PROVIDER}`, { userId: user_id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        getAll();
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  };

  const adminRejectUser = async () => {
    try {
      const result = await postRequest(`${API.ADMIN_REJECT_PROVIDER}`, { userId: user_id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        getAll();
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  };

  const adminDeleteUser = async (id) => {
    try {
      const result = await postRequest(`${API.DELETE_CUSTOMER_USER}`, { userId: id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        getAll();
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  };

  const handleNextPage = () => {
    setPageNum(prevPageNum => {
      const newPageNum = prevPageNum + 1;
      setSkip((newPageNum - 1) * limit);
      return newPageNum;
    });
  }

  const handlePrePage = () => {
    setPageNum(prevPageNum => {
      const newPageNum = prevPageNum > 1 ? prevPageNum - 1 : 1;
      setSkip((newPageNum - 1) * limit);
      return newPageNum;
    });
  };

  useEffect(() => {
    getAll()
  }, [pageNum, search]);




  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <div className="pagetitle">Provider management</div>
        </div>
        <div className="col-lg-5 offset-lg-1">
          <Search_input
            placeholder={"Search here"}
            value={search}
            onChange={searchType} // Ensure that the onChange event is connected.
            icon={<Search />}
            className="search_input"
          />
        </div>
        <div className="col-lg-12">
          <div className="pagetitle" style={{ fontSize: "16px" }}>
            Row PerPage - {limit}&nbsp;&nbsp; Total Provider - {totalDoc}
          </div>
        </div>
      </div>
      {load ?
        <Loader />
        :
        <>
          <div className="row mt-3">
            {users && users.length > 0 ?
              <>
                <div className="table-responsive">
                  <table className="table table-lg">
                    <thead className="table-light">
                      <tr colspan="4">
                        <th>User</th>
                        <th>joining Date</th>
                        <th>Email address</th>
                        <th>Status</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr>
                          <td>
                          <div className="d-flex gap-2 align-items-center">

                            {user.profile_pic !== "" ?
                              <img src={ImageUrl + user.profile_pic} alt="" className='rounded-circle' />
                              :
                              <img src="/images/blank_pic.png" alt="" className='rounded-circle' />
                            }
                             <NavLink
                              to={`/provider-details/${user._id}`}
                              state={{ id: user._id }}
                            >
                              {user.full_name}
                            </NavLink>
                            </div>
                          </td>
                          <td>{moment(user.timestamp).format('D MMMM YYYY')}</td>
                          <td style={{ textTransform: 'lowercase' }}>
                            <NavLink
                              to={`/provider-details/${user._id}`}
                              state={{ id: user._id }}
                            >
                              {user.email}
                            </NavLink>
                          </td>
                          <td>
                            <Tooltip title="Pending Verification">
                              {!user.verifiedByAdmin ?
                                <>
                                  <div style={{ color: "#0F993E", cursor: "pointer" }} className="d-flex gap-1" data-bs-toggle="modal" data-bs-target="#verify_modal" onClick={() => verify(user._id)}>
                                    <Ok />Accept
                                  </div>
                                  <div style={{ color: "#FF4B26", cursor: 'pointer' }} className="d-flex gap-1 mt-2" data-bs-toggle="modal" data-bs-target="#reject_modal" onClick={() => verify(user._id)}>
                                    <Cancel />
                                    Decline
                                  </div>
                                </>
                                :user.isDeleted?
                                'Deleted'
                                :
                                'Verified'
                              }
                            </Tooltip>
                          </td>
                          <td>
                            <div className="actions d-flex gap-2 align-items-center">
                              <NavLink
                                to={`/provider-details/${user._id}`}
                                state={{ id: user._id }}
                              >
                                <div className="view">
                                  <Eye />
                                </div>
                              </NavLink>
                                <div
                                  className={user.adminBlock ? "blockgreen iconCursor" : "block iconCursor"}
                                  data-bs-toggle="modal"
                                  data-bs-target="#provider_block_modal"
                                  onClick={() => BlockStatus(user.adminBlock, user._id)}
                                >
                                  <div
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <Block />
                                  </div>
                                </div>
                              <div className="delete iconCursor" data-bs-toggle="modal" data-bs-target="#customer_delete_modal" onClick={() => DeleteUser(user._id)}  >
                                <div
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Delete User"
                                >
                                  <Delete />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
              :
              <div className="emptyData">
                <EmptyData className="mt-4 mb-3" />
                <h4>User not found</h4>
              </div>
            }
            {users && users.length === 0 ?
              null
              :
              <>
                <div className="d-flex justify-content-center gap-1 mb-4 w-100 align-items-center">
                  <button className="paginationIcon" onClick={handlePrePage} disabled={skip === 0}><LeftIcon /></button>
                  {pageNum}
                  <button className="paginationIcon" onClick={handleNextPage} disabled={pageNum * limit >= totalDoc}><RightIcon /></button>
                </div>
              </>}
          </div>
        </>}
      <div className="modal fade" id="provider_block_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div className="modal-body text-center">
              <div className="modal_icon green">
                <Block />
              </div>
              <div className="modal_msg">
                Do you want {status ? 'UnBlock' : 'Block'} this Provider?
              </div>
              <div className="modal_btns">
                <button className="purple" onClick={() => adminBlockUser(user_id)} data-bs-dismiss="modal" aria-label="Close" >
                  Yes
                </button>
                <button data-bs-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="customer_delete_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body text-center">
              <div className="modal_icon red">
                <Delete />
              </div>
              <div className="modal_msg">
                Do you want to delete this account
              </div>
              <div className="modal_btns ">
                <button className="purple" onClick={() => adminDeleteUser(user_id)} data-bs-dismiss="modal" aria-label="Close" >
                  Yes
                </button>
                <button data-bs-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="verify_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body text-center">
              <div className="modal_icon">
                <Ok />
              </div>
              <div className="modal_msg">Do you want to Accept the request</div>
              <div className="modal_btns ">
                <button className="purple" onClick={() => adminVerifyUser(user_id)} data-bs-dismiss="modal" aria-label="Close">
                  Yes
                </button>
                <button data-bs-dismiss="modal" >No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="reject_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body text-center">
              <div className="modal_icon">
                <Ok />
              </div>
              <div className="modal_msg">Do you want to Decline the request</div>
              <div className="modal_btns ">
                <button className="purple" onClick={() => adminRejectUser(user_id)} data-bs-dismiss="modal" aria-label="Close">
                  Yes
                </button>
                <button data-bs-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
