import React from "react";

const Home = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6999 4.68373L10.8999 1.3254C9.59154 0.408729 7.5832 0.458729 6.32487 1.43373L2.14987 4.69206C1.31654 5.34206 0.658203 6.6754 0.658203 7.7254V13.4754C0.658203 15.6004 2.3832 17.3337 4.5082 17.3337H13.4915C15.6165 17.3337 17.3415 15.6087 17.3415 13.4837V7.83373C17.3415 6.70873 16.6165 5.3254 15.6999 4.68373ZM9.62487 14.0004C9.62487 14.3421 9.34154 14.6254 8.99987 14.6254C8.6582 14.6254 8.37487 14.3421 8.37487 14.0004V11.5004C8.37487 11.1587 8.6582 10.8754 8.99987 10.8754C9.34154 10.8754 9.62487 11.1587 9.62487 11.5004V14.0004Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
const Circle = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
      </svg>
    </>
  )
}
const User = () => {
  return (
    <>
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.64 10.9344C10.2411 10.9344 13.28 11.5195 13.28 13.7771C13.28 16.0356 10.2211 16.6 6.64 16.6C3.03977 16.6 0 16.0148 0 13.7572C0 11.4988 3.05887 10.9344 6.64 10.9344ZM6.64 0C9.07946 0 11.034 1.95384 11.034 4.39157C11.034 6.82931 9.07946 8.78398 6.64 8.78398C4.20138 8.78398 2.24599 6.82931 2.24599 4.39157C2.24599 1.95384 4.20138 0 6.64 0Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
const Service = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1876_15485)">
          <path
            d="M10.7597 0.818359C10.9814 0.818359 11.1518 0.99916 11.1518 1.2144V3.98667C11.1518 5.56222 12.439 6.85365 13.999 6.86226C14.6468 6.86226 15.1583 6.87087 15.5504 6.87087L15.6956 6.87023C15.9577 6.86824 16.3105 6.86226 16.6159 6.86226C16.829 6.86226 16.9995 7.03445 16.9995 7.24969V14.1718C16.9995 16.3069 15.2861 18.0375 13.1721 18.0375H6.38675C4.17043 18.0375 2.36328 16.2208 2.36328 13.9824V4.70127C2.36328 2.5661 4.08519 0.818359 6.20774 0.818359H10.7597ZM11.6718 11.9247H7.0346C6.6851 11.9247 6.39528 12.2088 6.39528 12.5618C6.39528 12.9148 6.6851 13.2075 7.0346 13.2075H11.6718C12.0213 13.2075 12.3111 12.9148 12.3111 12.5618C12.3111 12.2088 12.0213 11.9247 11.6718 11.9247ZM9.91581 7.6199H7.0346C6.6851 7.6199 6.39528 7.91263 6.39528 8.26562C6.39528 8.61861 6.6851 8.90272 7.0346 8.90272H9.91581C10.2653 8.90272 10.5551 8.61861 10.5551 8.26562C10.5551 7.91263 10.2653 7.6199 9.91581 7.6199ZM12.394 1.59838C12.394 1.22731 12.8398 1.04307 13.0947 1.31083C14.0162 2.27854 15.6264 3.97031 16.5266 4.91564C16.7755 5.17651 16.5931 5.60957 16.2342 5.61043C15.5335 5.61302 14.7075 5.61043 14.1134 5.60441C13.1706 5.60441 12.394 4.82008 12.394 3.86786V1.59838Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1876_15485">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
const Payment = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
        <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
        <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z" />
      </svg>
    </>
  );
};
const Payment2 = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
        <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
        <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z" />
      </svg>
    </>
  );
}
const Star = () => {
  return (
    <>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4266 10.7119C14.1918 10.9394 14.084 11.2684 14.1375 11.591L14.9431 16.0498C15.0111 16.4277 14.8516 16.8101 14.5353 17.0285C14.2254 17.2551 13.813 17.2823 13.475 17.101L9.46119 15.0076C9.32163 14.9333 9.16666 14.8934 9.00806 14.8889H8.76247C8.67728 14.9016 8.59391 14.9287 8.51778 14.9704L4.50307 17.0738C4.3046 17.1735 4.07985 17.2089 3.85963 17.1735C3.32313 17.072 2.96515 16.5609 3.05306 16.0217L3.85963 11.5629C3.9131 11.2376 3.80525 10.9068 3.57053 10.6757L0.298045 7.50379C0.0243564 7.23826 -0.0708005 6.83951 0.0542628 6.47972C0.175701 6.12085 0.48564 5.85894 0.859924 5.80003L5.36401 5.14662C5.70658 5.11128 6.00745 4.90284 6.16152 4.59471L8.14621 0.525628C8.19334 0.435002 8.25406 0.351627 8.32747 0.280939L8.40903 0.217501C8.45162 0.170376 8.50056 0.131407 8.55494 0.0996881L8.65372 0.0634379L8.80778 0H9.18931C9.53007 0.035344 9.83004 0.239251 9.98682 0.543753L11.9978 4.59471C12.1428 4.89106 12.4246 5.09678 12.75 5.14662L17.2541 5.80003C17.6347 5.85441 17.9528 6.11722 18.0788 6.47972C18.1975 6.84313 18.0951 7.24188 17.816 7.50379L14.4266 10.7119Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

// const HalfStar = () => {
//   return (
//     <>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="16"
//         height="16"
//         fill="currentColor"
//         class="bi bi-star-half"
//         viewBox="0 0 16 16"
//       >
//         <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" />
//       </svg>
//     </>
//   );
// };

const HalfStar = () => {
  return (
    <>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4266 10.7119C14.1918 10.9394 14.084 11.2684 14.1375 11.591L14.9431 16.0498C15.0111 16.4277 14.8516 16.8101 14.5353 17.0285C14.2254 17.2551 13.813 17.2823 13.475 17.101L9.46119 15.0076C9.32163 14.9333 9.16666 14.8934 9.00806 14.8889H8.76247C8.67728 14.9016 8.59391 14.9287 8.51778 14.9704L4.50307 17.0738C4.3046 17.1735 4.07985 17.2089 3.85963 17.1735C3.32313 17.072 2.96515 16.5609 3.05306 16.0217L3.85963 11.5629C3.9131 11.2376 3.80525 10.9068 3.57053 10.6757L0.298045 7.50379C0.0243564 7.23826 -0.0708005 6.83951 0.0542628 6.47972C0.175701 6.12085 0.48564 5.85894 0.859924 5.80003L5.36401 5.14662C5.70658 5.11128 6.00745 4.90284 6.16152 4.59471L8.14621 0.525628C8.19334 0.435002 8.25406 0.351627 8.32747 0.280939L8.40903 0.217501C8.45162 0.170376 8.50056 0.131407 8.55494 0.0996881L8.65372 0.0634379L8.80778 0H9.18931C9.53007 0.035344 9.83004 0.239251 9.98682 0.543753L11.9978 4.59471C12.1428 4.89106 12.4246 5.09678 12.75 5.14662L17.2541 5.80003C17.6347 5.85441 17.9528 6.11722 18.0788 6.47972C18.1975 6.84313 18.0951 7.24188 17.816 7.50379L14.4266 10.7119ZM8 0.525628L8.65372 3.38944L12.75 3.79493L9.99791 6.96434L10.6516 10.1197L8 8.73769L5.34841 10.1197L6.00209 6.96434L3.25 3.79493L7.34628 3.38944L8 0.525628Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};


const Annoucement = () => {
  return (
    <>
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.637 0C14.1204 0 13.776 0.3444 13.776 0.861V1.3776C12.3123 3.1857 10.0737 4.305 7.749 4.305H2.583C1.1193 4.305 0 5.4243 0 6.888V8.61C0 10.0737 1.1193 11.193 2.583 11.193H3.0135L0.9471 16.0146C0.7749 16.4451 0.9471 16.9617 1.3776 17.1339C1.4637 17.22 1.6359 17.22 1.722 17.22H5.166C5.5104 17.22 5.8548 17.0478 5.9409 16.7034L8.2656 11.193C10.4181 11.3652 12.3984 12.3984 13.776 14.0343V14.5509C13.776 15.0675 14.1204 15.4119 14.637 15.4119C15.1536 15.4119 15.498 15.0675 15.498 14.5509V0.7749C15.498 0.4305 15.1536 0 14.637 0ZM2.583 9.471C2.0664 9.471 1.722 9.1266 1.722 8.61V6.888C1.722 6.3714 2.0664 6.027 2.583 6.027H3.444V9.471H2.583ZM4.5633 15.498H3.0135L4.8216 11.193H6.3714L4.5633 15.498Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
const Disclaimer = () => {
  return (
    <>
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.12767 0C9.61197 0 10.0423 0.252323 10.2787 0.674893L18.0857 14.6382C18.3164 15.0507 18.3114 15.5404 18.0722 15.9483C17.8329 16.3564 17.4077 16.6 16.9347 16.6H1.32059C0.847548 16.6 0.422324 16.3564 0.183025 15.9484C-0.0561755 15.5405 -0.0611883 15.0507 0.169608 14.6381L7.97668 0.674942C8.21308 0.252323 8.64337 0 9.12767 0Z"
          fill="currentColor"
        />
        <path
          d="M8.52894 12.1979H9.72814C9.83469 12.1979 9.92188 12.113 9.92468 12.0065L10.1242 4.41366C10.1256 4.36063 10.1055 4.30932 10.0685 4.27133C10.0315 4.23334 9.98076 4.21191 9.92773 4.21191H8.32945C8.27642 4.21191 8.22565 4.23334 8.18864 4.27133C8.15164 4.30932 8.13153 4.36063 8.13296 4.41366L8.3325 12.0065C8.3352 12.113 8.42234 12.1979 8.52894 12.1979ZM9.12854 14.7958C9.67757 14.7958 10.1243 14.3489 10.1243 13.7996C10.1243 13.2506 9.67762 12.8039 9.12854 12.8039C8.57946 12.8039 8.13281 13.2505 8.13281 13.7996C8.13281 14.3489 8.57951 14.7958 9.12854 14.7958Z"
          fill="#fff"
        />
      </svg>
    </>
  );
};
const Eye = () => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99935 2.33301C8.20585 2.33301 9.34976 2.75159 10.3457 3.52654C11.3416 4.29584 12.1896 5.42149 12.7985 6.82998C12.8441 6.93745 12.8441 7.0619 12.7985 7.16371C11.5806 9.98069 9.41236 11.6663 6.99935 11.6663H6.99366C4.58634 11.6663 2.41805 9.98069 1.20016 7.16371C1.15463 7.0619 1.15463 6.93745 1.20016 6.82998C2.41805 4.01301 4.58634 2.33301 6.99366 2.33301H6.99935ZM6.99935 4.73705C5.74163 4.73705 4.72293 5.74957 4.72293 6.99967C4.72293 8.24412 5.74163 9.25664 6.99935 9.25664C8.25138 9.25664 9.27008 8.24412 9.27008 6.99967C9.27008 5.74957 8.25138 4.73705 6.99935 4.73705ZM7.00003 5.58429C7.77971 5.58429 8.4171 6.21782 8.4171 6.99843C8.4171 7.77338 7.77971 8.40692 7.00003 8.40692C6.21467 8.40692 5.57727 7.77338 5.57727 6.99843C5.57727 6.90227 5.58865 6.81176 5.60572 6.72126H5.63418C6.26589 6.72126 6.77808 6.22348 6.80084 5.60126C6.86345 5.58995 6.93174 5.58429 7.00003 5.58429Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
const Block = () => {
  return (
    <>
      <svg
        width="17"
        height="17"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3736 2.62637C10.3359 1.58869 8.96311 1 7.5 1C4.47807 1 2 3.4732 2 6.5C2 9.52839 4.47231 12 7.5 12C10.5144 12 13 9.53813 13 6.5C13 5.03689 12.4113 3.66404 11.3736 2.62637ZM7.5 10.6895C5.18429 10.6895 3.31055 8.82063 3.31055 6.5C3.31055 5.59763 3.59414 4.74041 4.11621 4.03142L9.96858 9.87732C9.25955 10.4059 8.40237 10.6895 7.5 10.6895ZM10.8773 8.96853L5.03142 3.11621C5.74045 2.59414 6.59763 2.31055 7.5 2.31055C9.81578 2.31055 11.6895 4.17937 11.6895 6.5C11.6895 7.40232 11.4059 8.25955 10.8773 8.96853Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
const Delete = () => {

  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0478 5.07365C11.1641 5.07365 11.2696 5.1244 11.353 5.21015C11.4307 5.30173 11.4699 5.41548 11.4585 5.53565C11.4585 5.57531 11.1476 9.50694 10.9701 11.1618C10.8589 12.1774 10.2042 12.794 9.22214 12.8109C8.46704 12.8278 7.72895 12.8337 7.00221 12.8337C6.23065 12.8337 5.47612 12.8278 4.7437 12.8109C3.79457 12.7882 3.13932 12.1605 3.03379 11.1618C2.85112 9.50111 2.5459 5.57531 2.54022 5.53565C2.53455 5.41548 2.57313 5.30173 2.65142 5.21015C2.72857 5.1244 2.83977 5.07365 2.95664 5.07365H11.0478ZM8.20442 1.16699C8.72012 1.16699 9.18079 1.52691 9.31411 2.04023L9.40942 2.46606C9.48657 2.81314 9.78725 3.05872 10.1333 3.05872H11.8342C12.0611 3.05872 12.25 3.24714 12.25 3.48689V3.70855C12.25 3.94246 12.0611 4.13671 11.8342 4.13671H2.16641C1.93892 4.13671 1.75 3.94246 1.75 3.70855V3.48689C1.75 3.24714 1.93892 3.05872 2.16641 3.05872H3.86725C4.21275 3.05872 4.51343 2.81314 4.59115 2.46665L4.68022 2.06882C4.81865 1.52691 5.27421 1.16699 5.79557 1.16699H8.20442Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
const Search = () => {
  return (
    <>
      <svg
        className="search_icon"
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
      </svg>
    </>
  );
};
const Pencil = () => {
  return (
    <>
      <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5747 15.5127C16.9099 15.5127 17.1819 15.7848 17.1819 16.1199C17.1819 16.4551 16.9099 16.7271 16.5747 16.7271H10.7028C10.3676 16.7271 10.0956 16.4551 10.0956 16.1199C10.0956 15.7848 10.3676 15.5127 10.7028 15.5127H16.5747ZM12.8229 1.92304C12.8634 1.95462 14.2178 3.00708 14.2178 3.00708C14.7101 3.30015 15.0946 3.82396 15.2404 4.4441C15.3853 5.05777 15.28 5.69087 14.9424 6.22601C14.9402 6.22958 14.9379 6.2331 14.9301 6.2436L14.924 6.2517C14.8692 6.32392 14.631 6.62513 13.4287 8.13145C13.4176 8.15119 13.4049 8.16968 13.3911 8.18764C13.3708 8.21413 13.3487 8.23847 13.3252 8.26064C13.2432 8.36389 13.1566 8.47238 13.0655 8.58644L12.881 8.81755C12.5002 9.29438 12.0486 9.85981 11.5128 10.5305L11.2379 10.8746C10.2035 12.1693 8.87873 13.8269 7.1817 15.95C6.8101 16.4131 6.2531 16.6827 5.65481 16.69L2.70872 16.7272H2.70062C2.41969 16.7272 2.1752 16.5345 2.10962 16.2601L1.44657 13.45C1.30975 12.8679 1.44576 12.2672 1.81898 11.8008L9.44531 2.26226C9.44855 2.25902 9.45098 2.25497 9.45422 2.25174C10.2905 1.25189 11.8028 1.10455 12.8229 1.92304ZM8.59502 5.26936L2.76701 12.5594C2.62857 12.7327 2.57756 12.9561 2.62857 13.1707L3.1799 15.5063L5.64024 15.4756C5.87421 15.4731 6.09037 15.3687 6.23367 15.1906C6.9716 14.2673 7.89904 13.1069 8.85257 11.9137L9.18993 11.4915L9.52786 11.0686C10.4221 9.94951 11.3056 8.84376 12.0448 7.91823L8.59502 5.26936ZM10.3893 3.02651L9.3536 4.32053L12.8031 6.96864C13.467 6.137 13.9038 5.58926 13.9442 5.53705C14.077 5.3217 14.1288 5.01729 14.0592 4.72422C13.9879 4.42386 13.8009 4.16884 13.5313 4.00612C13.4738 3.96645 12.1097 2.9075 12.0676 2.87431C11.5543 2.46304 10.8054 2.53428 10.3893 3.02651Z"
          fill="#CA92EC"
        />
      </svg>
    </>
  );
};
const Back = () => {
  return (
    <>
      <svg
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.375 23.25L11.625 15.5L19.375 7.75"
          stroke="#56535B"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
const More = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20px"
        width="20px"
        fill="#56535B"
        viewBox="0 0 448 512"
      >
        <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
      </svg>
    </>
  );
};
const Ok = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2183_34614)">
          <path
            d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
            fill="#0F993E"
          />
          <path
            d="M32.1417 12.4294C31.7657 12.1666 31.3008 12.0638 30.849 12.1436C30.3972 12.2234 29.9957 12.4794 29.7325 12.8551L18.7992 28.4719L15.066 24.7386C14.7395 24.4233 14.3023 24.2489 13.8485 24.2528C13.3947 24.2568 12.9606 24.4388 12.6397 24.7597C12.3188 25.0806 12.1368 25.5147 12.1329 25.9685C12.1289 26.4223 12.3034 26.8595 12.6187 27.1859L17.811 32.3782C18.1355 32.7028 18.5756 32.8852 19.0346 32.8853C19.0848 32.8879 19.135 32.8879 19.1852 32.8853C19.437 32.8632 19.681 32.7862 19.8998 32.6597C20.1187 32.5333 20.3072 32.3604 20.4521 32.1532L32.5675 14.8455C32.6987 14.6591 32.7918 14.4486 32.8414 14.2261C32.8911 14.0036 32.8962 13.7735 32.8567 13.549C32.8171 13.3245 32.7336 13.11 32.6109 12.9179C32.4882 12.7257 32.3288 12.5597 32.1417 12.4294Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2183_34614">
            <rect width="45" height="45" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
const Cancel = () => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2183_35108)">
          <path
            d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
            fill="#FF4B26"
          />
          <path
            d="M32.381 12.622C32.0565 12.2975 31.6163 12.1152 31.1574 12.1152C30.6984 12.1152 30.2583 12.2975 29.9337 12.622L22.5035 20.0522L15.0733 12.622C14.7469 12.3067 14.3097 12.1323 13.8559 12.1362C13.4021 12.1401 12.968 12.3222 12.6471 12.6431C12.3262 12.964 12.1442 13.3981 12.1403 13.8519C12.1363 14.3057 12.3108 14.7429 12.626 15.0693L20.0562 22.4995L12.626 29.9297C12.4607 30.0893 12.3289 30.2803 12.2382 30.4915C12.1475 30.7026 12.0997 30.9297 12.0977 31.1596C12.0957 31.3894 12.1395 31.6173 12.2265 31.83C12.3136 32.0427 12.4421 32.2359 12.6046 32.3984C12.7671 32.5609 12.9603 32.6895 13.173 32.7765C13.3857 32.8635 13.6137 32.9073 13.8435 32.9053C14.0733 32.9033 14.3004 32.8556 14.5115 32.7648C14.7227 32.6741 14.9137 32.5423 15.0733 32.377L22.5035 24.9468L29.9337 32.377C30.2602 32.6923 30.6974 32.8667 31.1512 32.8628C31.605 32.8588 32.0391 32.6768 32.36 32.3559C32.6809 32.035 32.8629 31.6009 32.8668 31.1471C32.8708 30.6933 32.6963 30.2561 32.381 29.9297L24.9508 22.4995L32.381 15.0693C32.7055 14.7447 32.8878 14.3046 32.8878 13.8456C32.8878 13.3867 32.7055 12.9465 32.381 12.622Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2183_35108">
            <rect width="45" height="45" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
const Profile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_2440_2599)">
        <path
          d="M9.64 12.9344C13.2411 12.9344 16.28 13.5195 16.28 15.7771C16.28 18.0356 13.2211 18.6 9.64 18.6C6.03977 18.6 3 18.0148 3 15.7572C3 13.4988 6.05887 12.9344 9.64 12.9344ZM9.64 2C12.0795 2 14.034 3.95384 14.034 6.39157C14.034 8.82931 12.0795 10.784 9.64 10.784C7.20138 10.784 5.24599 8.82931 5.24599 6.39157C5.24599 3.95384 7.20138 2 9.64 2Z"
          fill="#56535B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2440_2599">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Lock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.1854 17.9998H6.41579C5.52037 18.0089 4.65791 17.6625 4.01756 17.0365C3.37722 16.4106 3.01128 15.5562 3 14.6608V10.7109C3.01128 9.81554 3.37722 8.96118 4.01756 8.33523C4.65791 7.70928 5.52037 7.36286 6.41579 7.37194H13.1854C14.0808 7.36309 14.9432 7.70974 15.5834 8.33585C16.2235 8.96196 16.5893 9.81642 16.6003 10.7118V14.6608C16.589 15.5561 16.2232 16.4103 15.5831 17.0362C14.9429 17.6621 14.0807 18.0087 13.1854 17.9998ZM9.80016 11.108C9.70825 11.1069 9.61702 11.1239 9.53171 11.1581C9.44639 11.1922 9.36866 11.2429 9.30299 11.3072C9.23731 11.3715 9.18497 11.4482 9.14898 11.5327C9.11299 11.6173 9.09406 11.7082 9.09326 11.8001V13.5647C9.09326 13.7533 9.1682 13.9342 9.30158 14.0676C9.43497 14.201 9.61588 14.276 9.80452 14.276C9.99316 14.276 10.1741 14.201 10.3075 14.0676C10.4408 13.9342 10.5158 13.7533 10.5158 13.5647V11.8001C10.5123 11.6135 10.4351 11.4359 10.301 11.3062C10.1668 11.1765 9.98673 11.1052 9.80016 11.108Z"
        fill="#56535B"
      />
      <path
        d="M14.219 6.31686V7.49327C13.9276 7.41321 13.6267 7.37328 13.3245 7.37458H12.7965V6.31686C12.787 5.53242 12.4667 4.78379 11.9058 4.23532C11.3449 3.68686 10.5893 3.38338 9.80483 3.39153C9.02218 3.38287 8.26781 3.68379 7.70601 4.22876C7.1442 4.77372 6.82048 5.5186 6.80533 6.30115V7.37458H6.2817C5.97757 7.3709 5.6746 7.41267 5.38281 7.49851V6.31686C5.39777 5.16112 5.86966 4.0583 6.69534 3.24947C7.52102 2.44064 8.63332 1.99157 9.78913 2.00043C10.3636 1.99236 10.934 2.09767 11.4677 2.31033C12.0014 2.523 12.4879 2.83884 12.8994 3.23978C13.3109 3.64072 13.6392 4.11888 13.8657 4.64689C14.0921 5.1749 14.2122 5.74238 14.219 6.31686Z"
        fill="#56535B"
      />
    </svg>
  );
};

const Logout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_2440_2618)">
        <path
          d="M9.87897 1.7C11.9395 1.7 13.62 3.3517 13.62 5.3852V9.3609H8.55314C8.19001 9.3609 7.90289 9.6431 7.90289 10C7.90289 10.3486 8.19001 10.6391 8.55314 10.6391H13.62V14.6065C13.62 16.64 11.9395 18.3 9.86208 18.3H5.74948C3.68051 18.3 2 16.6483 2 14.6148V5.3935C2 3.3517 3.68895 1.7 5.75792 1.7H9.87897ZM15.7284 7.13667C15.9774 6.87937 16.3841 6.87937 16.6331 7.12837L19.0567 9.54367C19.1812 9.66817 19.2476 9.82587 19.2476 10.0002C19.2476 10.1662 19.1812 10.3322 19.0567 10.4484L16.6331 12.8637C16.5086 12.9882 16.3426 13.0546 16.1849 13.0546C16.0189 13.0546 15.8529 12.9882 15.7284 12.8637C15.4794 12.6147 15.4794 12.208 15.7284 11.959L17.0564 10.6393L13.62 10.6391V9.3609L17.0564 9.36107L15.7284 8.04137C15.4794 7.79237 15.4794 7.38567 15.7284 7.13667Z"
          fill="#56535B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2440_2618">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const EditICON = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5742 14.5127C15.9094 14.5127 16.1814 14.7847 16.1814 15.1199C16.1814 15.4551 15.9094 15.7271 15.5742 15.7271H9.70228C9.36711 15.7271 9.09508 15.4551 9.09508 15.1199C9.09508 14.7847 9.36711 14.5127 9.70228 14.5127H15.5742ZM11.8224 0.923031C11.8629 0.954605 13.2174 2.00707 13.2174 2.00707C13.7096 2.30014 14.0941 2.82395 14.2399 3.44409C14.3848 4.05776 14.2795 4.69086 13.9419 5.226C13.9397 5.22957 13.9374 5.23309 13.9296 5.24359L13.9235 5.25169C13.8687 5.32391 13.6305 5.62512 12.4282 7.13144C12.4171 7.15118 12.4044 7.16967 12.3906 7.18763C12.3703 7.21412 12.3483 7.23846 12.3247 7.26063C12.2427 7.36388 12.1561 7.47237 12.065 7.58643L11.8805 7.81754C11.4997 8.29437 11.0481 8.85979 10.5123 9.53044L10.2374 9.87461C9.20298 11.1693 7.87824 12.8269 6.18121 14.95C5.80961 15.4131 5.25261 15.6827 4.65432 15.6899L1.70823 15.7272H1.70013C1.41921 15.7272 1.17471 15.5345 1.10913 15.26L0.446079 12.45C0.309258 11.8679 0.445269 11.2672 0.81849 10.8008L8.44482 1.26225C8.44806 1.25901 8.45049 1.25496 8.45373 1.25172C9.29003 0.251881 10.8023 0.104536 11.8224 0.923031ZM7.59453 4.26935L1.76652 11.5594C1.62808 11.7327 1.57708 11.9561 1.62808 12.1707L2.17941 14.5063L4.63975 14.4756C4.87372 14.4731 5.08988 14.3687 5.23318 14.1906C5.97111 13.2673 6.89855 12.1069 7.85209 10.9137L8.18944 10.4915L8.52737 10.0686C9.42163 8.9495 10.3051 7.84374 11.0443 6.91822L7.59453 4.26935ZM9.3888 2.0265L8.35312 3.32051L11.8026 5.96862C12.4665 5.13699 12.9033 4.58925 12.9437 4.53704C13.0765 4.32169 13.1283 4.01728 13.0587 3.72421C12.9874 3.42385 12.8004 3.16883 12.5308 3.0061C12.4733 2.96643 11.1092 1.90749 11.0671 1.8743C10.5538 1.46303 9.80493 1.53427 9.3888 2.0265Z"
        fill="#CA92EC"
      />
    </svg>
  );
};

const RegionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe-americas" viewBox="0 0 16 16">
      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
    </svg>
  );
};

const ChangeImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="white"
      />
      <path
        d="M23.75 12C23.75 18.4893 18.4893 23.75 12 23.75C5.51065 23.75 0.25 18.4893 0.25 12C0.25 5.51065 5.51065 0.25 12 0.25C18.4893 0.25 23.75 5.51065 23.75 12Z"
        stroke="#56535B"
        stroke-opacity="0.3"
        stroke-width="0.5"
      />
      <path
        d="M12.8484 7C13.3863 7.00554 13.8244 7.2825 14.0795 7.78102C14.1453 7.91257 14.2372 8.10471 14.3334 8.3074L14.4495 8.55248L14.5065 8.67282L14.5619 8.79468C14.5841 8.83345 14.6229 8.86115 14.6728 8.86115C16.0037 8.86115 17.0906 9.94682 17.0906 11.2762V14.5665C17.0906 15.8958 16.0037 16.9815 14.6728 16.9815H8.41775C7.08133 16.9815 6 15.8958 6 14.5665V11.2762C6 9.94682 7.08133 8.86115 8.41775 8.86115C8.46211 8.86115 8.50647 8.83899 8.52311 8.79468L8.55638 8.72821C8.71165 8.4014 8.90018 8.00258 9.01109 7.78102C9.26617 7.2825 9.69871 7.00554 10.2366 7H12.8484ZM11.5453 10.5506C10.963 10.5506 10.414 10.7777 9.99815 11.1931C9.5878 11.6086 9.36044 12.1514 9.36599 12.7275C9.36599 13.3091 9.59335 13.8519 10.0037 14.2673C10.4196 14.6772 10.963 14.9043 11.5453 14.9043C12.1442 14.9043 12.6876 14.6606 13.0813 14.2673C13.475 13.8741 13.719 13.3312 13.7246 12.7275C13.7246 12.1514 13.4972 11.603 13.0869 11.1876C12.6765 10.7777 12.1275 10.5506 11.5453 10.5506ZM11.5453 11.3815C11.9057 11.3815 12.244 11.5199 12.4991 11.7747C12.7542 12.0295 12.8928 12.3674 12.8928 12.7275C12.8872 13.4697 12.2884 14.0735 11.5453 14.0735C11.1848 14.0735 10.8466 13.935 10.5915 13.6802C10.3364 13.4254 10.1978 13.0875 10.1978 12.7275V12.7219C10.1922 12.373 10.3309 12.0351 10.586 11.7803C10.8466 11.5199 11.1848 11.3815 11.5453 11.3815ZM14.6562 10.4176C14.3789 10.4176 14.1571 10.6447 14.1571 10.9217C14.1571 11.1987 14.3789 11.4202 14.6562 11.4202C14.9335 11.4202 15.1608 11.1987 15.1608 10.9217C15.1608 10.6447 14.9335 10.4176 14.6562 10.4176Z"
        fill="#56535B"
      />
    </svg>
  );
};

const IDIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16">
      <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5z" />
      <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1m-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1" />
    </svg>
  )
}

const Archived = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
      <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8z" />
    </svg>
  )
}

const UnArchived = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-radioactive" viewBox="0 0 16 16">
      <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8" />
      <path d="M9.653 5.496A3 3 0 0 0 8 5c-.61 0-1.179.183-1.653.496L4.694 2.992A5.97 5.97 0 0 1 8 2c1.222 0 2.358.365 3.306.992zm1.342 2.324a3 3 0 0 1-.884 2.312 3 3 0 0 1-.769.552l1.342 2.683c.57-.286 1.09-.66 1.538-1.103a6 6 0 0 0 1.767-4.624zm-5.679 5.548 1.342-2.684A3 3 0 0 1 5.005 7.82l-2.994-.18a6 6 0 0 0 3.306 5.728ZM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
    </svg>
  )
}
const Filter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
      <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
    </svg>
  )
}

const LeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#56535B" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
      <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
    </svg>
  )
}

const RightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#56535B" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
    </svg>
  )
}

const EmptyData = () => {
  return (
    <svg width="257" height="213" viewBox="0 0 257 213" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M122.66 45.452C121.071 44.4515 120.085 43.1241 119.704 41.46C119.322 39.7958 119.575 38.2653 120.451 36.8636C121.353 35.4273 122.621 34.5457 124.245 34.2188C125.875 33.8968 127.485 34.2336 129.075 35.2292C130.699 36.2495 131.714 37.557 132.111 39.1519C132.507 40.7467 132.254 42.2623 131.353 43.7036C130.476 45.1053 129.213 46.0018 127.569 46.3931C125.924 46.7844 124.285 46.4723 122.66 45.452ZM127.291 28.4882C128.124 27.1658 129.08 26.1257 130.154 25.3778C131.229 24.6299 132.348 24.0652 133.512 23.6888C134.671 23.3074 135.845 23.0152 137.034 22.8121C138.218 22.6041 139.337 22.3961 140.392 22.1881C141.447 21.98 142.408 21.673 143.27 21.2619C144.132 20.8557 144.825 20.2316 145.35 19.3996C146.207 18.0375 146.336 16.5863 145.741 15.0558C145.147 13.5204 143.923 12.1732 142.066 11.0093C140.288 9.88991 138.465 9.26088 136.603 9.1222C134.741 8.98352 132.938 9.2906 131.194 10.0434L126.856 1.69774C129.817 0.340634 133.077 -0.189324 136.633 0.117758C140.189 0.42484 143.874 1.77699 147.698 4.17421C150.536 5.95727 152.779 7.9335 154.429 10.0979C156.078 12.2673 157.029 14.511 157.282 16.8389C157.534 19.1618 156.95 21.46 155.528 23.7334C154.602 25.2094 153.557 26.3733 152.388 27.2252C151.219 28.0771 150.016 28.6913 148.772 29.0726C147.529 29.454 146.276 29.7512 145.013 29.9592C143.745 30.1673 142.542 30.3852 141.393 30.618C140.243 30.8458 139.198 31.1925 138.247 31.6531C137.296 32.1137 136.504 32.8567 135.86 33.877L127.291 28.4882Z" fill="#B57EDC" />
      <path d="M104.424 22.8666C104.498 22.2228 104.671 21.6631 104.944 21.1876C105.216 20.7121 105.533 20.301 105.905 19.9543C106.271 19.6027 106.662 19.2857 107.068 18.9984C107.475 18.7111 107.861 18.4338 108.218 18.1663C108.574 17.8988 108.881 17.6116 109.134 17.3045C109.386 16.9974 109.535 16.6408 109.585 16.2347C109.664 15.571 109.451 15.0014 108.956 14.5308C108.46 14.0603 107.757 13.773 106.851 13.669C105.984 13.565 105.186 13.6542 104.463 13.9365C103.74 14.2139 103.116 14.6547 102.596 15.249L99.4756 12.8914C100.347 11.8513 101.477 11.0737 102.868 10.5536C104.255 10.0336 105.885 9.88498 107.752 10.1029C109.139 10.2664 110.332 10.6081 111.338 11.1282C112.343 11.6482 113.101 12.3218 113.611 13.149C114.121 13.9761 114.315 14.9419 114.186 16.0514C114.102 16.7696 113.913 17.3936 113.631 17.9236C113.344 18.4536 113.002 18.8993 112.606 19.2609C112.205 19.6274 111.789 19.9593 111.353 20.2664C110.917 20.5685 110.501 20.8706 110.11 21.1579C109.718 21.4501 109.386 21.7671 109.114 22.1088C108.842 22.4506 108.673 22.8716 108.614 23.3718L104.424 22.8666ZM105.711 30.0682C104.934 29.9791 104.329 29.6522 103.889 29.0974C103.448 28.5427 103.27 27.9186 103.349 27.2351C103.433 26.5318 103.75 25.9771 104.305 25.566C104.859 25.1549 105.523 24.9915 106.301 25.0856C107.093 25.1797 107.707 25.4868 108.143 26.0167C108.574 26.5467 108.752 27.1608 108.673 27.8592C108.594 28.5427 108.277 29.1024 107.732 29.5432C107.177 29.984 106.504 30.1574 105.711 30.0682Z" fill="#EFEAF8" />
      <path d="M135.548 64.1196C135.39 63.0201 135.607 62.0642 136.212 61.2519C136.816 60.4396 137.603 59.9641 138.569 59.8254C139.565 59.6818 140.446 59.9146 141.219 60.5238C141.992 61.133 142.457 61.9899 142.616 63.0894C142.779 64.2137 142.581 65.1746 142.012 65.9671C141.447 66.7595 140.664 67.2251 139.669 67.3737C138.698 67.5173 137.812 67.2895 137.004 66.7001C136.202 66.1058 135.716 65.2439 135.548 64.1196ZM144.973 59.6917C145.89 59.558 146.722 59.5877 147.475 59.7858C148.228 59.9839 148.911 60.2712 149.525 60.6476C150.139 61.024 150.724 61.4401 151.274 61.8957C151.823 62.3514 152.343 62.7774 152.844 63.1687C153.344 63.5649 153.849 63.8769 154.364 64.1097C154.879 64.3425 155.424 64.4168 156.004 64.3326C156.945 64.1939 157.648 63.6986 158.114 62.8418C158.574 61.9849 158.713 60.9151 158.525 59.6323C158.346 58.399 157.93 57.339 157.277 56.4426C156.623 55.5461 155.796 54.8527 154.785 54.3574L156.88 49.1964C158.629 50.0087 160.12 51.2766 161.348 52.9953C162.576 54.714 163.384 56.8982 163.77 59.5431C164.057 61.5045 164.027 63.2776 163.681 64.8527C163.334 66.4277 162.69 67.7204 161.739 68.7259C160.788 69.7313 159.53 70.3504 157.96 70.5832C156.94 70.7318 156.014 70.707 155.181 70.5089C154.349 70.3108 153.611 70.0037 152.963 69.5877C152.314 69.1766 151.7 68.7209 151.12 68.2306C150.541 67.7402 149.976 67.2796 149.436 66.8536C148.891 66.4227 148.337 66.0859 147.762 65.8333C147.187 65.5807 146.553 65.5064 145.845 65.6105L144.973 59.6917Z" fill="#FBA5CF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.367188 94.2731C0.367188 91.4599 2.64554 89.1815 5.45881 89.1815C33.3785 89.1815 187.237 89.1815 215.161 89.1815C217.975 89.1815 220.253 91.4599 220.253 94.2731C220.253 110.256 220.253 159.335 220.253 175.323C220.253 178.136 217.975 180.415 215.161 180.415C187.242 180.415 39.8966 180.415 11.9769 180.415C9.16361 180.415 0.372141 178.136 0.372141 175.323C0.367189 159.335 0.367188 110.256 0.367188 94.2731Z" fill="#EFEAF8" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.367188 94.1345C0.367188 92.8219 0.88725 91.5639 1.8184 90.6327C2.7446 89.7016 4.0076 89.1815 5.32013 89.1815H215.3C218.034 89.1815 220.253 91.4004 220.253 94.1345C220.253 96.1107 220.253 97.9284 220.253 97.9284H0.367188C0.367188 97.9284 0.367188 96.1107 0.367188 94.1345Z" fill="#B57EDC" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M220.253 169.256H0.367188V175.496C0.367188 178.211 2.56629 180.41 5.2805 180.41C32.8684 180.41 187.747 180.41 215.335 180.41C218.049 180.41 220.248 178.211 220.248 175.496V169.256H220.253Z" fill="#B57EDC" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M49.4314 171.96C51.0163 171.96 52.309 173.248 52.309 174.838C52.309 176.428 51.0213 177.715 49.4314 177.715C47.8464 177.715 46.5537 176.428 46.5537 174.838C46.5537 173.248 47.8464 171.96 49.4314 171.96Z" fill="white" />
      <path d="M202.18 172.856H63.6709V173.51H202.18V172.856Z" fill="white" />
      <path d="M136.048 176.16H63.6709V176.814H136.048V176.16Z" fill="white" />
      <path d="M62.9524 129.736H15.315C13.4279 129.736 11.8975 128.201 11.8975 126.319V112.376C11.8975 110.489 13.4329 108.959 15.315 108.959H62.9524C64.8395 108.959 66.3699 110.494 66.3699 112.376V126.319C66.3699 128.201 64.8395 129.736 62.9524 129.736ZM15.315 109.607C13.7895 109.607 12.5463 110.851 12.5463 112.376V126.319C12.5463 127.844 13.7895 129.087 15.315 129.087H62.9524C64.4779 129.087 65.7211 127.844 65.7211 126.319V112.376C65.7211 110.851 64.4779 109.607 62.9524 109.607H15.315Z" fill="#B57EDC" />
      <path d="M59.8965 115.789H18.3711V116.442H59.8965V115.789Z" fill="white" />
      <path d="M59.8965 122.252H18.3711V122.906H59.8965V122.252Z" fill="white" />
      <path d="M62.9524 160.638H15.315C13.4279 160.638 11.8975 159.102 11.8975 157.22V143.278C11.8975 141.39 13.4329 139.86 15.315 139.86H62.9524C64.8395 139.86 66.3699 141.395 66.3699 143.278V157.22C66.3699 159.107 64.8395 160.638 62.9524 160.638ZM15.315 140.509C13.7895 140.509 12.5463 141.752 12.5463 143.278V157.22C12.5463 158.746 13.7895 159.989 15.315 159.989H62.9524C64.4779 159.989 65.7211 158.746 65.7211 157.22V143.278C65.7211 141.752 64.4779 140.509 62.9524 140.509H15.315Z" fill="#B57EDC" />
      <path d="M59.8965 146.69H18.3711V147.344H59.8965V146.69Z" fill="white" />
      <path d="M59.8965 153.154H18.3711V153.807H59.8965V153.154Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M185.84 73.4262C185.84 70.6922 188.054 68.4733 190.793 68.4733C203.477 68.4733 237.033 68.4733 249.718 68.4733C252.452 68.4733 254.666 70.6922 254.666 73.4262C254.666 83.1389 254.666 104.536 254.666 113.6C254.666 114.714 254.225 115.779 253.438 116.561C252.65 117.349 251.585 117.79 250.476 117.79C238.183 117.79 202.323 117.79 190.03 117.79C188.916 117.79 187.851 117.349 187.068 116.561C186.281 115.774 185.84 114.709 185.84 113.6C185.84 104.536 185.84 83.1389 185.84 73.4262Z" fill="#FBA5CF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M185.84 73.4262C185.84 72.1137 186.36 70.8556 187.291 69.9245C188.222 68.9983 189.48 68.4733 190.793 68.4733H249.718C251.03 68.4733 252.288 68.9933 253.22 69.9245C254.151 70.8556 254.671 72.1137 254.671 73.4262C254.671 75.4024 254.671 77.2202 254.671 77.2202H185.84C185.84 77.2251 185.84 75.4074 185.84 73.4262Z" fill="#FDDBEA" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M191.927 71.1528C192.864 71.1528 193.626 71.9156 193.626 72.8517C193.626 73.7878 192.864 74.5506 191.927 74.5506C190.991 74.5506 190.229 73.7878 190.229 72.8517C190.224 71.9106 190.986 71.1528 191.927 71.1528Z" fill="#FBA5CF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M198.019 71.1528C198.955 71.1528 199.718 71.9156 199.718 72.8517C199.718 73.7878 198.955 74.5506 198.019 74.5506C197.083 74.5506 196.32 73.7878 196.32 72.8517C196.315 71.9106 197.078 71.1528 198.019 71.1528Z" fill="#FBA5CF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M204.106 71.1528C205.042 71.1528 205.805 71.9156 205.805 72.8517C205.805 73.7878 205.042 74.5506 204.106 74.5506C203.17 74.5506 202.407 73.7878 202.407 72.8517C202.407 71.9106 203.17 71.1528 204.106 71.1528Z" fill="#FBA5CF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9082 67.0865C43.9082 65.6451 45.0771 64.4812 46.5134 64.4812H99.1682C100.609 64.4812 101.773 65.6501 101.773 67.0865V93.2726C101.773 94.714 100.605 95.8779 99.1682 95.8779H77.9399L82.4272 110.132L64.6907 95.8779H46.5134C45.0721 95.8779 43.9082 94.709 43.9082 93.2726V67.0865Z" fill="#FDDBEA" />
      <path d="M94.7068 73.6591L84.0391 84.3271L84.5014 84.7894L95.1691 74.1214L94.7068 73.6591Z" fill="white" />
      <path d="M84.5014 73.6466L84.0391 74.1089L94.7071 84.7766L95.1694 84.3143L84.5014 73.6466Z" fill="white" />
      <path d="M77.9411 73.6591L67.2734 84.3271L67.7357 84.7894L78.4034 74.1214L77.9411 73.6591Z" fill="white" />
      <path d="M67.7357 73.6469L67.2734 74.1093L77.9415 84.7769L78.4038 84.3146L67.7357 73.6469Z" fill="white" />
      <path d="M61.1755 73.6566L50.5078 84.3247L50.9701 84.787L61.6378 74.1189L61.1755 73.6566Z" fill="white" />
      <path d="M50.9701 73.6499L50.5078 74.1122L61.1759 84.7799L61.6382 84.3176L50.9701 73.6499Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M82.8291 200.752C82.8291 197.829 85.1966 195.462 88.1188 195.462C105.132 195.462 158.307 195.462 175.315 195.462C178.238 195.462 180.605 197.829 180.605 200.752C180.605 202.876 180.605 205.318 180.605 207.443C180.605 210.365 178.238 212.733 175.315 212.733C158.302 212.733 105.132 212.733 88.1188 212.733C85.1966 212.733 82.8291 210.365 82.8291 207.443C82.8291 205.323 82.8291 202.876 82.8291 200.752Z" fill="#B57EDC" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M151.68 200.752C151.68 199.35 152.239 198.003 153.23 197.012C154.221 196.021 155.568 195.462 156.97 195.462C162.185 195.462 170.105 195.462 175.315 195.462C176.717 195.462 178.064 196.017 179.055 197.012C180.045 198.003 180.605 199.35 180.605 200.752C180.605 202.975 180.605 205.546 180.605 207.735C180.605 210.494 178.366 212.733 175.607 212.733C170.402 212.733 162.279 212.733 156.965 212.733C155.563 212.733 154.216 212.173 153.225 211.182C152.234 210.192 151.675 208.845 151.675 207.443C151.68 205.323 151.68 202.876 151.68 200.752Z" fill="#FBA5CF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M170.175 202.381C170.175 200.157 168.367 198.349 166.143 198.349C163.919 198.349 162.111 200.157 162.111 202.381C162.111 204.605 163.919 206.413 166.143 206.413C166.381 206.413 166.609 206.388 166.836 206.348L168.674 209.845L170.001 209.147L168.248 205.813C169.407 205.1 170.175 203.832 170.175 202.381ZM162.948 202.381C162.948 200.618 164.38 199.181 166.143 199.181C167.906 199.181 169.338 200.613 169.338 202.381C169.338 204.144 167.906 205.576 166.143 205.576C164.38 205.576 162.948 204.144 162.948 202.381Z" fill="#F0ECF9" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M254.359 160.301H200.02C198.579 160.301 197.415 161.47 197.415 162.906V200.994C197.415 202.435 198.584 203.599 200.02 203.599H216.766L221.793 212.614L229.654 203.599H254.364C255.805 203.599 256.969 202.43 256.969 200.994V162.906C256.964 161.47 255.8 160.301 254.359 160.301Z" fill="#F7F7F8" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M210.892 177.651C213.324 177.651 215.296 179.627 215.296 182.054C215.296 184.486 213.319 186.457 210.892 186.457C208.461 186.457 206.489 184.481 206.489 182.054C206.489 179.627 208.461 177.651 210.892 177.651Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M227.187 177.651C229.619 177.651 231.591 179.627 231.591 182.054C231.591 184.486 229.614 186.457 227.187 186.457C224.755 186.457 222.784 184.481 222.784 182.054C222.784 179.627 224.755 177.651 227.187 177.651Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M243.487 177.651C245.919 177.651 247.89 179.627 247.89 182.054C247.89 184.486 245.914 186.457 243.487 186.457C241.055 186.457 239.084 184.481 239.084 182.054C239.084 179.627 241.055 177.651 243.487 177.651Z" fill="white" />
      <path d="M215.24 85.3132V96.1552H213.973L207.464 88.0671V96.1552H205.914V85.3132H207.182L213.69 93.4014V85.3132H215.24Z" fill="#B57EDC" />
      <path d="M219.063 92.0541C219.063 89.6074 220.876 87.8689 223.338 87.8689C225.799 87.8689 227.597 89.6024 227.597 92.0541C227.597 94.5058 225.799 96.2542 223.338 96.2542C220.876 96.2542 219.063 94.5009 219.063 92.0541ZM226.102 92.0541C226.102 90.3057 224.923 89.1715 223.343 89.1715C221.763 89.1715 220.569 90.3008 220.569 92.0541C220.569 93.8025 221.763 94.9516 223.343 94.9516C224.923 94.9516 226.102 93.8025 226.102 92.0541Z" fill="#B57EDC" />
      <path d="M235.83 95.6798C235.379 96.0661 234.701 96.2543 234.032 96.2543C232.373 96.2543 231.432 95.338 231.432 93.6838V89.1766H230.035V87.9532H231.432V86.1553H232.918V87.9532H235.27V89.1766H232.918V93.6243C232.918 94.5059 233.383 95.0012 234.206 95.0012C234.641 95.0012 235.057 94.8626 235.369 94.6149L235.83 95.6798Z" fill="#B57EDC" />
      <path d="M199.876 99.3102V100.023H202.264V101.247H199.911V108.235H198.425V101.247H197.028V100.023H198.425V99.2953C198.425 97.7005 199.386 96.6455 201.134 96.6455C201.768 96.6455 202.387 96.799 202.808 97.1259L202.358 98.2552C202.046 98.0224 201.644 97.8689 201.214 97.8689C200.327 97.8738 199.876 98.3691 199.876 99.3102Z" fill="#B57EDC" />
      <path d="M202.912 104.129C202.912 101.683 204.725 99.9442 207.187 99.9442C209.648 99.9442 211.446 101.678 211.446 104.129C211.446 106.576 209.648 108.33 207.187 108.33C204.725 108.33 202.912 106.576 202.912 104.129ZM209.95 104.129C209.95 102.381 208.771 101.247 207.191 101.247C205.611 101.247 204.418 102.376 204.418 104.129C204.418 105.878 205.611 107.027 207.191 107.027C208.771 107.027 209.95 105.883 209.95 104.129Z" fill="#B57EDC" />
      <path d="M221.164 100.023V108.235H219.753V106.997C219.148 107.849 218.128 108.33 216.964 108.33C214.844 108.33 213.433 107.166 213.433 104.749V100.023H214.918V104.58C214.918 106.19 215.726 106.997 217.132 106.997C218.683 106.997 219.673 106.036 219.673 104.268V100.023H221.164Z" fill="#B57EDC" />
      <path d="M231.793 103.51V108.235H230.307V103.679C230.307 102.069 229.5 101.277 228.093 101.277C226.513 101.277 225.493 102.223 225.493 104.006V108.235H224.007V100.023H225.433V101.262C226.038 100.425 227.093 99.9442 228.39 99.9442C230.381 99.9492 231.793 101.093 231.793 103.51Z" fill="#B57EDC" />
      <path d="M242.298 96.7397V108.235H240.872V106.933C240.208 107.864 239.153 108.325 237.945 108.325C235.542 108.325 233.794 106.636 233.794 104.125C233.794 101.613 235.542 99.9393 237.945 99.9393C239.108 99.9393 240.129 100.375 240.812 101.257V96.7347H242.298V96.7397ZM240.827 104.129C240.827 102.381 239.633 101.247 238.068 101.247C236.488 101.247 235.295 102.376 235.295 104.129C235.295 105.878 236.488 107.027 238.068 107.027C239.633 107.027 240.827 105.883 240.827 104.129Z" fill="#B57EDC" />
      <path d="M114.686 131.712V110.42H117.687L124.512 121.192C126.092 123.689 127.325 125.932 128.336 128.112L128.4 128.082C128.148 125.239 128.083 122.648 128.083 119.33V110.42H130.669V131.712H127.89L121.129 120.91C119.643 118.543 118.222 116.106 117.147 113.803L117.053 113.832C117.212 116.517 117.276 119.077 117.276 122.614V131.712H114.686Z" fill="#B57EDC" />
      <path d="M156.825 120.846C156.825 128.176 152.373 132.059 146.934 132.059C141.313 132.059 137.36 127.701 137.36 121.257C137.36 114.496 141.56 110.073 147.251 110.073C153.066 110.073 156.825 114.526 156.825 120.846ZM140.302 121.192C140.302 125.744 142.769 129.815 147.093 129.815C151.451 129.815 153.918 125.804 153.918 120.969C153.918 116.735 151.709 112.312 147.128 112.312C142.581 112.317 140.302 116.517 140.302 121.192Z" fill="#B57EDC" />
      <path d="M99.837 135.581C101.511 135.328 103.502 135.14 105.681 135.14C109.629 135.14 112.442 136.056 114.305 137.795C116.202 139.533 117.306 141.995 117.306 145.442C117.306 148.919 116.231 151.762 114.24 153.718C112.249 155.709 108.965 156.784 104.825 156.784C102.863 156.784 101.224 156.69 99.832 156.532V135.581H99.837ZM102.586 154.412C103.279 154.54 104.29 154.57 105.364 154.57C111.239 154.57 114.433 151.286 114.433 145.536C114.463 140.514 111.62 137.324 105.81 137.324C104.389 137.324 103.314 137.453 102.586 137.606V154.412Z" fill="#B57EDC" />
      <path d="M124.32 149.895L122.106 156.591H119.263L126.499 135.298H129.817L137.083 156.591H134.146L131.873 149.895H124.32ZM131.303 147.745L129.218 141.618C128.743 140.227 128.426 138.964 128.114 137.73H128.049C127.732 138.993 127.386 140.291 126.974 141.584L124.889 147.745H131.303Z" fill="#B57EDC" />
      <path d="M144.255 137.636H137.776V135.298H153.542V137.636H147.033V156.591H144.255V137.636Z" fill="#B57EDC" />
      <path d="M159.357 149.895L157.143 156.591H154.3L161.536 135.298H164.855L172.12 156.591H169.183L166.91 149.895H159.357ZM166.335 147.745L164.25 141.618C163.775 140.227 163.458 138.964 163.146 137.73H163.081C162.764 138.993 162.418 140.291 162.007 141.584L159.921 147.745H166.335Z" fill="#B57EDC" />
      <path d="M96.152 201.375V207.111H95.4784L92.0361 202.832V207.111H91.2139V201.375H91.8875L95.3298 205.655V201.375H96.152Z" fill="white" />
      <path d="M98.1777 204.937C98.1777 203.644 99.1386 202.723 100.441 202.723C101.744 202.723 102.695 203.639 102.695 204.937C102.695 206.229 101.744 207.156 100.441 207.156C99.1386 207.156 98.1777 206.234 98.1777 204.937ZM101.897 204.937C101.897 204.01 101.273 203.411 100.436 203.411C99.5992 203.411 98.9702 204.01 98.9702 204.937C98.9702 205.863 99.5992 206.467 100.436 206.467C101.273 206.472 101.897 205.863 101.897 204.937Z" fill="white" />
      <path d="M109.961 202.728V203.49C109.896 203.481 109.837 203.481 109.782 203.481C108.94 203.481 108.415 203.996 108.415 204.947V207.111H107.628V202.767H108.381V203.495C108.658 202.99 109.198 202.728 109.961 202.728Z" fill="white" />
      <path d="M115.617 205.199H112.09C112.189 205.962 112.803 206.472 113.665 206.472C114.175 206.472 114.601 206.299 114.918 205.947L115.354 206.457C114.963 206.918 114.364 207.161 113.641 207.161C112.239 207.161 111.303 206.234 111.303 204.942C111.303 203.654 112.229 202.728 113.482 202.728C114.735 202.728 115.632 203.629 115.632 204.966C115.632 205.031 115.627 205.125 115.617 205.199ZM112.09 204.635H114.879C114.8 203.906 114.25 203.386 113.487 203.386C112.729 203.391 112.174 203.897 112.09 204.635Z" fill="white" />
      <path d="M116.994 206.65L117.321 206.026C117.688 206.289 118.282 206.477 118.847 206.477C119.575 206.477 119.877 206.254 119.877 205.888C119.877 204.912 117.133 205.759 117.133 204.025C117.133 203.248 117.831 202.723 118.946 202.723C119.51 202.723 120.149 202.871 120.526 203.114L120.189 203.738C119.797 203.485 119.362 203.391 118.936 203.391C118.247 203.391 117.91 203.644 117.91 203.991C117.91 205.016 120.664 204.179 120.664 205.868C120.664 206.655 119.941 207.156 118.787 207.156C118.069 207.16 117.356 206.938 116.994 206.65Z" fill="white" />
      <path d="M126.519 202.767V207.111H125.771V206.457C125.449 206.908 124.909 207.161 124.295 207.161C123.171 207.161 122.428 206.546 122.428 205.269V202.767H123.215V205.179C123.215 206.031 123.641 206.457 124.389 206.457C125.206 206.457 125.731 205.947 125.731 205.016V202.772H126.519V202.767Z" fill="white" />
      <path d="M128.836 201.029H129.623V207.111H128.836V201.029Z" fill="white" />
      <path d="M134.488 206.858C134.25 207.062 133.888 207.161 133.537 207.161C132.66 207.161 132.16 206.675 132.16 205.799V203.411H131.422V202.762H132.16V201.811H132.947V202.762H134.196V203.411H132.947V205.764C132.947 206.229 133.195 206.492 133.626 206.492C133.854 206.492 134.077 206.418 134.24 206.289L134.488 206.858Z" fill="white" />
      <path d="M135.711 206.65L136.038 206.026C136.404 206.289 136.999 206.477 137.563 206.477C138.291 206.477 138.594 206.254 138.594 205.888C138.594 204.912 135.85 205.759 135.85 204.025C135.85 203.248 136.548 202.723 137.662 202.723C138.227 202.723 138.866 202.871 139.242 203.114L138.906 203.738C138.514 203.485 138.078 203.391 137.652 203.391C136.964 203.391 136.627 203.644 136.627 203.991C136.627 205.016 139.381 204.179 139.381 205.868C139.381 206.655 138.658 207.156 137.504 207.156C136.786 207.16 136.073 206.938 135.711 206.65Z" fill="white" />
    </svg>
  )
}

const Address = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
    </svg>
  )
}
export {
  ChangeImage,
  EditICON,
  LeftIcon,
  Address,
  Profile,
  Archived,
  EmptyData,
  IDIcon,
  Filter,
  RightIcon,
  Logout,
  Lock,
  UnArchived,
  Home,
  User,
  Service,
  Payment,
  Annoucement,
  Disclaimer,
  Payment2,
  Star,
  Eye,
  Block,
  Delete,
  Search,
  Pencil,
  Back,
  More,
  RegionIcon,
  Circle,
  Ok,
  Cancel,
  HalfStar,
};
