// import React from 'react'

// export default function Search_input({ label, htmlFor, name, value, type, placeholder, required, icon, onChange, className, accept, loader, autoComplete, ...props }) {
//     return (
//         <>
//             <div className="search_box d-flex align-items-center justify-content-end">
//                 <div className={"w-400 form-group position-relative " + className}>
//                     <input type={type} name={name}
//                         placeholder={`${placeholder} ${required ? '*' : ''}`} {...props} htmlFor />
//                     <span className='search_icons'>
//                         {icon}
//                     </span>
//                 </div>
//                 <button className='blank_btn pe-0'><svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M0 10C0 4.47715 4.47715 0 10 0H33C38.5228 0 43 4.47715 43 10V33C43 38.5228 38.5228 43 33 43H10C4.47715 43 0 38.5228 0 33V10Z" fill="#CA92EC" />
//                     <path d="M18.5293 27.123L12.0003 27.123" stroke="white" stroke-width="2" stroke-linecap="round" />
//                     <path d="M30.2812 27.123L18.5293 27.123" stroke="white" stroke-opacity="0.25" stroke-width="2" stroke-linecap="round" />
//                     <path d="M21.3467 27C21.3467 25.3431 20.0035 24 18.3467 24C16.6898 24 15.3467 25.3431 15.3467 27C15.3467 28.6569 16.6898 30 18.3467 30C20.0035 30 21.3467 28.6569 21.3467 27Z" fill="white" stroke="white" stroke-width="1.5" />
//                     <path d="M23.752 14.877L30.281 14.877" stroke="white" stroke-width="2" stroke-linecap="round" />
//                     <path d="M12 14.877L23.752 14.877" stroke="white" stroke-opacity="0.25" stroke-width="2" stroke-linecap="round" />
//                     <path d="M20.9346 15C20.9346 16.6569 22.2777 18 23.9346 18C25.5914 18 26.9346 16.6569 26.9346 15C26.9346 13.3431 25.5914 12 23.9346 12C22.2777 12 20.9346 13.3431 20.9346 15Z" fill="white" stroke="white" stroke-width="1.5" />
//                 </svg>
//                 </button>
//             </div>

//         </>
//     )
// }


import React from 'react';

export default function Search_input({
  label,
  name,
  value,
  type,
  placeholder,
  required,
  icon,
  onChange,
  className,
  accept,
  loader,
  autoComplete,
  ...props
}) {
  return (
    <div className="search_box d-flex align-items-center justify-content-end">
      <div className={"w-400 form-group position-relative " + className}>
        <input
          type={type}
          name={name}
          placeholder={`${placeholder} ${required ? '*' : ''}`}
          value={value}
          onChange={onChange}
          {...props}
        />
        <span className='search_icons'>{icon}</span>
      </div>
      <button className='blank_btn pe-0'>
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          {/* ... (your SVG content) */}
        </svg>
      </button>
    </div>
  );
}

