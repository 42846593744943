import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import server from '../server'
import { useNavigate } from 'react-router-dom'
import { postRequest } from '../ApiFunction'
import API from "../Api"

const ChangePassword = () => {

  const [obj, setObj] = useState({ old_password: "", new_password: "", confirm_password: "" })
  const [message, setMessage] = useState({})
  const token = localStorage.getItem("token");
  const [error, setError] = useState(false)
  const [load, setLoad] = useState(false)
  const nav = useNavigate()
  const addData = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };


  const submitPass = async (e) => {
    try {
      if (obj.old_password === "" || obj.new_password === "" || obj.confirm_password === "") {
        setError(true)
      } else if (obj.new_password.length < 8) {
        setError(true)
        setMessage({ message: "New Password must be at least 8 characters in length!" })
      } else if (obj.new_password !== obj.confirm_password) {
        setError(true)
        setMessage({ message: "Please ensure that the Confirm password matches the New password!" })
      } else {
        setLoad(true)
        const result = await postRequest(`${API.CHANGE_PASSWORD}`, obj)
        if (!result.data.status) {
          if (result.data.code === 203) {
            localStorage.clear()
            nav('/login')
            window.location.reload()
          } else if (result.data.code === 201) {
            setMessage({ message: result.data.message })
            setError(true)
            setLoad(false)
          } else {
            toast.error(result.data.message)
          }
        } else {
          setMessage("")
          toast.success(result.data.message)
          setError(false)
          setObj({ old_password: "", new_password: "", confirm_password: "" })
          setLoad(false)
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  };




  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <div className="pagetitle">
            Change Password
          </div>
        </div>
        <div className="col-lg-6">
          <div className='d-flex gap-2 justify-content-end align-items-center w-100'>
          </div>
        </div>
      </div>
      <div className='whiteProfilePage'>
        <div className='profileDetails'>
          <div className='details'>

            <div className='inputSet'>
              <h6>Old Password</h6>
              <input type='password' value={obj.old_password} name='old_password' id="old_password" onChange={addData} />
            </div>
            <div className='inputSet'>
              <h6>New Password</h6>
              <input type='password' value={obj.new_password} name='new_password' id="new_password" onChange={addData} />
            </div>
            <div className='inputSet'>
              <h6>Confirm Password</h6>
              <input type='password' value={obj.confirm_password} name='confirm_password' id="confirm_password" onChange={addData} />
            </div>
          </div>
        </div>
        {error && message.message !== "" ? <div className='errorMessage'>{message.message}</div> : null}
      </div>
      {obj.old_password === "" || obj.new_password === "" || obj.confirm_password === "" ?
        <div className="modal_btns" style={{ justifyContent: 'flex-start' }} onClick={submitPass}>
          <button className='purple' style={{ backgroundColor: "#d1ced3", cursor: "default" }}>
            Update
          </button>
        </div>
        :
        <div className="modal_btns" style={{ justifyContent: 'flex-start' }} onClick={submitPass}>
          <button className='purple'>
            {load ?
              <div className="spinner-border mt-2" style={{ width: "1rem", height: "1rem" }} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              :
              'Update'
            }
          </button>
        </div>
      }
    </>
  )
}

export default ChangePassword