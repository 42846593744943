import React, { useEffect, useState, } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { Back } from '../common/Icons';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import API  from "../Api"
import { getRequest } from '../ApiFunction';
import moment from 'moment';

export default function Service_details() {
    let { id } = useParams();
    const [serviceDetails, setServiceDetails] = useState({})

    const nav = useNavigate();
    const getServiceDetails = async () => {
        try {
            const result = await getRequest(`${API.GET_SERVICE_DETAILS}?userId=${id}`)
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                }else{
                    toast.error(result.data.message)
                }
            } else {
                setServiceDetails(result.data.data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    };




    const getServiceStatusLabel = (status) => {
        switch (status) {
          case 0:
            return "Pending";
          case 1:
            return "Pending";
          case 2:
            return "Cancelled";
          case 3:
            return "Pending";
          case 4:
            return "Pending";
          case 5:
            return "Completed";
          case 6:
            return "Cancelled";
          default:
            return ""; // Default label, you can change it to another label or remove it
        }
      };
    
    

    useEffect(() => {
        getServiceDetails()
    }, [])
    return (
        <>
            <div className="top_bar row align-items-center mb-4 mt-3">
                <div className="col-lg-12">
                    <div className="pagetitle">
                        <NavLink to={'/service-request'}>
                            <div className='d-flex align-items-center'><span><Back /></span>Service details</div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="outer_div">
                <div className="row">
                    <div className="col-lg-4 border_right">
                        <div className="sub_title">Customer Details</div>
                        <ul className="p-0 single_column mt-4">
                            <li>
                                <div className="detail_wrapper">
                                    <div className="small_heading">Full Name</div>
                                    <div className="name mt-2">{serviceDetails.customer_name}</div>
                                </div>
                            </li>
                            <li>
                                <div className="detail_wrapper">
                                    <div className="small_heading">Email Address</div>
                                    <div className="name mt-2" style={{textTransform:'lowercase'}}>{serviceDetails.customer_email}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-8 ps_100">
                        <div className="sub_title">Provider details</div>
                        <ul className="p-0 single_column mt-4">
                            <li>
                                <div className="detail_wrapper">
                                    <div className="small_heading">Full Name</div>
                                    <div className="name mt-2">{serviceDetails.provider_name}</div>
                                </div>
                            </li>
                            <li>
                                <div className="detail_wrapper">
                                    <div className="small_heading">Email Address</div>
                                    <div className="name mt-2" style={{textTransform:'lowercase'}}>{serviceDetails.provider_email}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-7">
                        <div className="light_gray">
                            <div className="sub_title">Booking details</div>
                            <ul className=" p-0 two_column mt-4">
                                <li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Booking ID</div>
                                        <div className="name mt-2">{serviceDetails.bookingId}</div>
                                    </div>
                                </li>
                                <li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Selected services</div>
                                        {serviceDetails.service_selected && serviceDetails.service_selected.map((e) => (
                                            <div className="name mt-2" key={e}>
                                                {e.add_title}
                                            </div>
                                        ))}
                                    </div>
                                </li>
                                <li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Number of persons</div>
                                        <div className="name mt-2">{serviceDetails.number_of_peoples}</div>
                                    </div>
                                </li>
                                <li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Address</div>
                                        <div className="name mt-2">{serviceDetails.booking_address && serviceDetails.booking_address.address_name}</div>
                                    </div>
                                </li>
                                <li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Date & TIme</div>
                                        <div className="name mt-2">{moment(serviceDetails.time_of_booking).format('DD-MM-YYYY, hh:mm a')}</div>
                                    </div>
                                </li>
                                <li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Service status</div>
                                        <div className="name mt-2"
                                            style={{
                                                color: serviceDetails.status === 0 || serviceDetails.status === 1 || serviceDetails.status === 3 || serviceDetails.status === 4
                                                    ? 'orange'
                                                    : serviceDetails.status === 2 || serviceDetails.status === 6
                                                        ? 'red'
                                                        : serviceDetails.status === 5
                                                            ? 'green'
                                                            : 'black',
                                            }}
                                        >{getServiceStatusLabel(serviceDetails.status)}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 ">
                        <div className="skyblue">
                            <div className="sub_title">Price details</div>
                            <ul className="p-0 single_column mt-4">
                                <li>
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Sub total</div>
                                        <div className="name mt-2">SAR {serviceDetails.sub_total}</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Platform Charges</div>
                                        <div className="name mt-2">SAR {serviceDetails.platform_fee}</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Total</div>
                                        <div className="name mt-2">SAR {serviceDetails.total_price}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}
