import React, { useEffect, useState } from "react";
import { getRequest } from "../ApiFunction";
import API from "../Api"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment"

export default function Notification() {
  const [notification, setNotification] = useState([]);
  const nav =  useNavigate()


  const fetchData = async () => {
    const result = await getRequest(`${API.GET_NOTIFICATION}`)
    if (!result.data.status) {
      if (result.data.code === 203) {
        localStorage.clear()
        nav('/login')
      }else{
        toast.error(result.data.message)
      }
    } else {
      setNotification(result.data.data)
    }

  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ul className="notification_list">
      {notification.map((item, index) => (
        <li key={index}>
          <div className="n_item">{item.message}</div>
          <div className="n_time">{moment(item.created_at).fromNow()}</div>
        </li>
      ))}
    </ul>
  );
}

