import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Back, EditICON } from "../common/Icons";
import Input from "../common/Input";
import server from "../server";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../Api"
import { getRequest, patchRequest } from '../ApiFunction';
import moment from "moment";



export default function PaymentPercentage() {
    const [obj, setObj] = useState({ percentage_value: '' })
    const [data, setData] = useState({ percentage_value: '' })

    const [obj2, setObj2] = useState({ platform_fee: '', provider_platform_charges: '' })
    const [data2, setData2] = useState({ platform_fee: '', provider_platform_charges: '' })
    const nav = useNavigate()
    const [load, setLoad] = useState(false)


    const addData = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value,
        });
        setObj2({
            ...obj2,
            [e.target.name]: e.target.value,
        });
    };

    const SaveValue = async () => {
        try {
            if (obj.percentage_value === '') {
                toast.error("Initial amount value is required. You cannot leave it empty or set it to zero.");
            } else {
                const result = await patchRequest(`${API.EDIT_PERCENTAGE_VALUE}`, obj)
                if (!result.data.status) {
                    if (result.data.code === 203) {
                        localStorage.clear()
                        nav('/login')
                        window.location.reload()
                    } else {
                        toast.error(result.data.message)
                    }
                } else {
                    fetchPercentage()
                    toast.success(result.data.message)
                    setObj({ percentage_value: '' })
                }
            }
        } catch (error) {
            console.log(error.message)
        }
    }




    const fetchPercentage = async () => {
        try {
            const result = await getRequest(`${API.GET_PERCENTAGE_VALUE}`);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                setObj(result.data.data[0]);
                setData(result.data.data[0])
                setLoad(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    };

    const SaveValue2 = async () => {
        try {
            if (obj.percentage_value === '') {
                toast.error("Initial amount value is required. You cannot leave it empty or set it to zero.");
            } else {
                const result = await patchRequest(`${API.EDIT_PLATFORM_FEE}`, obj)
                if (!result.data.status) {
                    if (result.data.code === 203) {
                        localStorage.clear()
                        nav('/login')
                        window.location.reload()
                    } else {
                        toast.error(result.data.message)
                    }
                } else {
                    fetchPercentage2()
                    toast.success(result.data.message)
                    setObj2({ platform_fee: '' })
                }
            }
        } catch (error) {
            console.log(error.message)
        }
    }


    const fetchPercentage2 = async () => {
        try {
            const result = await getRequest(`${API.GET_PLATFORM_FEE}`);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                setObj2(result.data.data[0]);
                setData2(result.data.data[0])
                setLoad(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    };



    useEffect(() => {
        fetchPercentage()
        fetchPercentage2()
    }, []);


    return (
        <>
            <div className="top_bar row align-items-center mb-4 mt-3">
                <div className="col-lg-12">
                    <div className="pagetitle">
                        <NavLink to={"/payment-management"}>
                            <div className="d-flex align-items-center">
                                <span>
                                    <Back />
                                </span>
                                Initial Amount
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="outer_div">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sub_title" style={{ textTransform: "none" }}>Currently, the initial payment value is {data.percentage_value} percent, and the platform fee value is also {data2.platform_fee} percent. You can change it anytime using the edit icon.</div>
                        <ul className="p-0 single_column mt-4">
                            <li>
                                <div className="detail_wrapper">
                                    <div className="name mt-2">
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="detail_wrapper">
                                    <div className="small_heading">Minimum initial amount &nbsp;&nbsp;
                                        <span style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#edit_payment_value">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="name mt-2">{data.percentage_value} Percent
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="detail_wrapper">
                                    <div className="small_heading">Customer Platform charges &nbsp;&nbsp;
                                        <span style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#edit_platform_value">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="name mt-2">{data2.platform_fee} Percent
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="detail_wrapper">
                                    <div className="small_heading">Provider Platform charges &nbsp;&nbsp;
                                        <span style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#edit_provider_value">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="name mt-2">{data2.provider_platform_charges} Rial
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="edit_payment_value" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        <div class="modal-body text-center">
                            <div className="modal_msg">Change the initial amount</div>
                            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                <input type="text" placeholder="%" name="percentage_value" id="percentage_value" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.percentage_value} onChange={addData} />
                            </div>
                            <div className="modal_btns ">
                                <button data-bs-dismiss="modal">Cancel</button>
                                <button className="purple" onClick={SaveValue} data-bs-dismiss="modal" aria-label="Close" >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="edit_platform_value" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        <div class="modal-body text-center">
                            <div className="modal_msg">Change the customer charges</div>
                            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                <input type="text" placeholder="%" name="platform_fee" id="platform_fee" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj2.platform_fee} onChange={addData} />
                            </div>
                            <div className="modal_btns ">
                                <button data-bs-dismiss="modal">Cancel</button>
                                <button className="purple" onClick={SaveValue2} data-bs-dismiss="modal" aria-label="Close" >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="edit_provider_value" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        <div class="modal-body text-center">
                            <div className="modal_msg">Change the provider charges</div>
                            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                <input type="text" placeholder="%" name="provider_platform_charges" id="provider_platform_charges" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj2.provider_platform_charges} onChange={addData} />
                            </div>
                            <div className="modal_btns ">
                                <button data-bs-dismiss="modal">Cancel</button>
                                <button className="purple" onClick={SaveValue2} data-bs-dismiss="modal" aria-label="Close" >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="green_btn mt-4">
        <button className="btns">Send money to Provider</button>
      </div> */}
        </>
    );
}
