import React  from "react";
import { Lock, Logout, Profile } from "./Icons";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { postRequest } from "../ApiFunction";
import API, { ImageUrl } from "../Api"
export default function Profile_dropdown() {
  const nav = useNavigate();
  const userInfo = useSelector(state => state.userinfo.user);


  const logout = async () => {
    try {
      const result = await postRequest(API.LOGOUT);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/')
        }else{
          toast.error(result.data.message)
        }
      } else {
        localStorage.clear();
        nav("/");
        window.location.reload();
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };


  return (
    <div className="profile">
      <div class="dropdown">
        {userInfo.profile_pic !== "" ? (
          <img src={ImageUrl + userInfo.profile_pic} alt={userInfo.name} className="rounded-circle" />
        ) : (
          <img src="/images/blank_pic.png" alt={userInfo.name} className="rounded-circle" />
        )}
        <button
          class="dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {userInfo.name}
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <NavLink to="/my-profile">
            <li>
              <a class="dropdown-item" href="#">
                <Profile />
                Profile
              </a>
            </li>
          </NavLink>
          <NavLink to="/change-password">
            <li>
              <a class="dropdown-item" href="#">
                <Lock />
                Change Password
              </a>
            </li>
          </NavLink>
          <li>
            <a class="dropdown-item" href="#" onClick={logout}>
              <div className="d-flex" >
                <Logout />
                Logout
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}