import React, { useEffect, useState } from "react";
import Search_input from "../common/Search_input";
import { Archived, Delete, EditICON, Filter, Search } from "../common/Icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API, { ImageUrl } from "../Api"
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../ApiFunction";
import Loader from "../common/Loader"

const RegionManagement = () => {
  const [page, setPage] = React.useState(0);
  const [load, setLoad] = useState(true);
  const [popup, setPopup] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const nav = useNavigate();
  const [users, setUsers] = useState([]);
  const [id, setId] = useState('')
  const [obj, setObj] = useState({ region_name: '', region_name_ar: '', is_active: true })
  const [filter, setFilter] = useState('')
  const [archivedcode, setCode] = useState(0)



  const Create = async () => {
    try {
      if (obj.region_name === "" || obj.region_name_ar === "") {
        toast.error("Please ensure you enter the region in both fields!")
      } else {
        const result = await postRequest(`${API.CREATE_REGION}`, obj)
        if (!result.data.status) {
          if (result.data.code === 203) {
            localStorage.clear()
            nav('/login')
            window.location.reload()
          } else {
            toast.error(result.data.message)
          }
        } else {
          fetchData()
          toast.success(result.data.message)
          setObj({ region_name: '', region_name_ar: '' })
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  }


  const addData = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };






  const fetchData = async () => {
    try {
      const result = await getRequest(`${API.GET_ALL_REGION}?search=${search}&is_active=${filter}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        setUsers(result.data.data);
        setLoad(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  };


  const searchType = async (e) => {
    setSearch(e.target.value);
  };



  const updateCategory = async () => {
    try {
      if (obj.region_name === "" || obj.region_name_ar === "") {
        toast.error("Please ensure you enter the region in both fields!")
      } else {
        const result = await patchRequest(`${API.EDIT_REGION}`, obj)
        if (!result.data.status) {
          if (result.data.code === 203) {
            localStorage.clear()
            nav('/login')
            window.location.reload()
          } else {
            toast.error(result.data.message)
          }
        } else {
          fetchData()
          toast.success(result.data.message)
          setObj({ region_name: '', region_name_ar: '' })
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Something went wrong");
    }
  };



  const deleteRegion = async () => {
    try {
      let data = {
        id: obj._id,
      }
      const result = await postRequest(`${API.DELETE_REGION}`, data);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else if (result.data.code === 306) {
          setCode(result.data.code)
        } else {
          toast.error(result.data.message)
        }
      } else {
        fetchData()
        setObj({})
        toast.success(result.data.message)
        setPopup(true);

      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const archivedRegion = async () => {
    try {
      let data = {
        id: obj._id,
        is_active: obj.is_active ? false : true
      }
      const result = await postRequest(`${API.DELETE_REGION_PER}`, data);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        fetchData()
        setObj({})
        setCode(0)
        setPopup(false)
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  }


  const editCategory = (user) => {
    setObj(user);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, search, filter]);


  console.log(archivedcode, popup)

  return (
    <>
      {load ?
        <Loader />
        :
        <>
          <div className="top_bar row align-items-center">
            <div className="col-lg-6">
              <Search_input placeholder="Search here" icon={<Search />} className="search_input" onChange={searchType} />
            </div>
            <div className="col-lg-4 offset-lg-2">
              <div className="editBtn" style={{ width: "200px" }} data-bs-toggle="modal" data-bs-target="#create_category_modal" onClick={() => { setObj({ region_name: '', region_name_ar: '' }) }}>
                Create Region
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {users.length === 0 ?
              <div className="w-100 text-center">Region not found.</div>
              :
              <>
                <div className="table-responsive">
                  <table className="table table-lg">
                    <thead className="table-light">
                      <tr colspan="4">
                        <th>S.No</th>
                        <th>Region in English</th>
                        <th>Region in Arabic</th>
                        <th id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">{filter === '' ? 'Filter' : filter} <Filter />
                          <div class="dropdown">
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                              {filter !== '' ? <li><a class="dropdown-item" href="#" onClick={() => { setFilter('') }}>All</a></li> : null}
                              <li><a class="dropdown-item" href="#" onClick={() => { setFilter('Archived') }}>Archived</a></li>
                              <li><a class="dropdown-item" href="#" onClick={() => { setFilter('Active') }}>Active</a></li>
                            </ul>
                          </div></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => (
                        <tr key={user._id}>
                          <td>{index + 1}</td>
                          <td>{user.region_name}</td>
                          <td>{user.region_name_ar}</td>
                          <td>
                            {user.is_active
                              ?
                              <span className="badge bg-success">Active</span>
                              :
                              <span className="badge bg-secondary">Archived</span>
                            }
                          </td>
                          <td>
                            <div className="actions">
                              <button className="edit" data-bs-toggle="modal" data-bs-target="#update_category_modal" onClick={() => editCategory(user)}  >
                                <EditICON />
                              </button>
                              {!user.is_active
                                ? <button className="archive" data-bs-toggle="modal" data-bs-target="#archived_modal" >
                                  <div data-bs-toggle="tooltip" data-bs-placement="top" type="Activate region" onClick={() => { setObj(user) }} >
                                    <Archived />
                                  </div>
                                </button> : null}
                              {user.is_active
                                ? <button className="delete" data-bs-toggle="modal" data-bs-target="#category_delete_modal" >
                                  <div data-bs-toggle="tooltip" data-bs-placement="top" type="Delete region" onClick={() => { setObj(user) }} >
                                    <Delete />
                                  </div>
                                </button> : null}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>}
            Note:- We support both languages according to the user's preference. If the user's language preference is English, we display only regions in English. Similarly, if the user's language preference is Arabic, we display regions in Arabic.<br /><br />
          </div>
        </>}
      {/* edit category modal from here */}
      <div class="modal fade" id="update_category_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body">
              <div className="modal_msg">Update region's here. We support two languages: English (en) and Arabic (ar), so you need to enter text in both fields make sure you enter correct data.</div>
              <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <div className="w-100 text-left mt-2">English</div>
                <input type="text" placeholder="enter here." name="region_name" id="region_name" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.region_name} onChange={addData} />
                <div className="w-100 text-left mt-2">Arabic</div>
                <input type="text" placeholder="enter here." name="region_name_ar" id="region_name_ar" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.region_name_ar} onChange={addData} />
              </div>
              <div className="modal_btns ">
                <button data-bs-dismiss="modal">Cancel</button>
                <button className="purple" onClick={updateCategory} data-bs-dismiss="modal" aria-label="Close" >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create category modal from here */}
      <div class="modal fade" id="create_category_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body">
              <div className="modal_msg">Create a new region here. We support two languages: English (en) and Arabic (ar), so you need to enter text in both fields make sure you enter correct data.</div>
              <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <div className="w-100 text-left mt-2">English</div>
                <input type="text" placeholder="enter here." name="region_name" id="region_name" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.region_name} onChange={addData} />
                <div className="w-100 text-left mt-2">Arabic</div>
                <input type="text" placeholder="enter here." name="region_name_ar" id="region_name_ar" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.region_name_ar} onChange={addData} />
              </div>
              <div className="modal_btns ">
                <button data-bs-dismiss="modal">Cancel</button>
                <button className="purple" onClick={Create} data-bs-dismiss="modal" aria-label="Close" >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="archived_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body text-center">
              <div className="modal_icon red">
                <Archived />
              </div>
              <div className="modal_msg">
                Do you want to Activate this region?
              </div>
              <div className="modal_btns ">
                <button className="purple" onClick={archivedRegion} data-bs-dismiss="modal"
                  aria-label="Close">Yes</button>
                <button data-bs-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete modal from here */}
      <div class="modal fade" id="category_delete_modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          {archivedcode === 306 ?
            (<div class="modal-content">
              <button type="button" class="btn-close" onClick={() => setCode(0)} data-bs-dismiss="modal" aria-label="Close" ></button>
              <div class="modal-body text-center">
                <div className="modal_icon red">
                  <Archived />
                </div>
                <div className="modal_msg" style={{ fontSize: "18px", lineHeight: "19px" }}>
                  You cannot delete this region; its in use. You can archive this region from the app.
                </div>
                <div className="modal_btns ">
                  <button className="purple" onClick={archivedRegion} data-bs-dismiss="modal"
                    aria-label="Close">Archive Now</button>
                  <button data-bs-dismiss="modal" onClick={() => setCode(0)}>Cancel</button>
                </div>
              </div>
            </div>)
            :
            (
              <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-body text-center">
                  <div className="modal_icon red">
                    <Delete />
                  </div>
                  <div className="modal_msg">
                    Do you want to delete this region?
                  </div>
                  <div className="modal_btns">
                    <button className="purple" onClick={deleteRegion}>Yes</button>
                    <button data-bs-dismiss="modal">No</button>
                  </div>
                </div>
              </div>)
          }
        </div>
      </div>
    </>
  );
};

export default RegionManagement;