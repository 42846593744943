import React from "react";

const PageNotFound = () => {
  return (
    <div className="notfound text-center mt-4">
      <h1>Either Page Not Found or You are not allowed to visit this page</h1>
    </div>
  );
};

export default PageNotFound;
