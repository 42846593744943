import React from 'react'
import { Block, Cancel, Delete, Ok } from './Icons'




export default function Modal() {
    
    return (
        <>
            <div className="pop_up">
                <div class="modal fade" id="verify_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="modal-body text-center">
                                <div className="modal_icon">
                                    <Ok />
                                </div>
                                <div className="modal_msg">
                                    Do you want to verify the request
                                </div>
                                <div className="modal_btns ">
                                    <button className='purple'>Yes</button>
                                    <button data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="decline_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="modal-body text-center">
                                <div className="modal_icon">
                                    <Cancel />
                                </div>
                                <div className="modal_msg">
                                    Do you want to decline the request
                                </div>
                                <div className="modal_btns ">
                                    <button className='purple'>Yes</button>
                                    <button data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="delete_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="modal-body text-center">
                                <div className="modal_icon red">
                                    <Delete />
                                </div>
                                <div className="modal_msg">
                                Do you want to delete this account
                                </div>
                                <div className="modal_btns ">
                                    <button className='purple'>Yes</button>
                                    <button data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="delete_rating_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="modal-body text-center">
                                <div className="modal_icon red">
                                    <Delete />
                                </div>
                                <div className="modal_msg">
                                Do you want to delete this rating
                                </div>
                                <div className="modal_btns ">
                                    <button className='purple' 
                                    >Yes</button>
                                    <button data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="block_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="modal-body text-center">
                                <div className="modal_icon green">
                                    <Block/>
                                </div>
                                <div className="modal_msg">
                                Do you want to suspend this account
                                </div>
                                <div className="modal_btns ">
                                    <button className='purple'>Yes</button>
                                    <button data-bs-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
