import React, { useEffect, useState, useRef } from "react";
import Table_profile from "../common/Table_profile";
import { Block, Cancel, Delete, Eye, Ok, Search } from "../common/Icons";
import Search_input from "../common/Search_input";
import { useNavigate } from "react-router-dom";
import server from "../server";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { getRequest } from "../ApiFunction";
import API from "../Api"
import Loader from "../common/Loader";

export default function Service_request() {
  const nav = useNavigate();
  const [load, setLoad] = useState(true)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [totalDoc, setTotalDoc] = useState("");

  const fetchData = async () => {
    try {
      const result = await getRequest(`${API.GET_BOOKING_SERVICES}?pagenumber=${page}&limit=${rowsPerPage}&search=${search}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        setUsers(result.data.data);
        setTotalDoc(result.data.totalDocuments);
        setLoad(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  };



  const searchType = async (e) => {
    setSearch(e.target.value);
  };



  const getServiceStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Pending";
      case 2:
        return "Cancelled";
      case 3:
        return "Pending";
      case 4:
        return "Pending";
      case 5:
        return "Completed";
      case 6:
        return "Cancelled";
      default:
        return ""; // Default label, you can change it to another label or remove it
    }
  };




  const redirect = (id) => {
    nav("/service-details/" + id);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, search]);

  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <div className="pagetitle">Services Request</div>
        </div>
        <div className="col-lg-5 offset-lg-1">
          <Search_input
            placeholder={"Search here"}
            icon={<Search />}
            onChange={searchType}
            className="search_input"
          />
        </div>
      </div>
      {load ?
        <Loader />
        :
        <>
          <div className="row mt-5">
            <div className="table-responsive">
              <table className="table table-lg">
                <thead className="table-light">
                  <tr colspan="4">
                    <th>booking Date & Time</th>
                    <th>Booking ID</th>
                    <th>Service name</th>
                    <th>service Status</th>
                    <th>payment mode</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.bookingID}>
                      <td>{moment(user.time_of_booking).format('DD-MM-YYYY, hh:mm a')}</td>
                      <td>{user.bookingId}</td>
                      <td>{user.service_selected.length>1?<>{user.service_selected[0].add_title}...</>:user.service_selected[0].add_title}</td>
                      <td>
                        <div className="status_action d-flex align-items-center">
                          <div className="name mt-2"
                            style={{
                              color: user.status === 0 || user.status === 1 || user.status === 3 || user.status === 4
                                ? 'orange'
                                : user.status === 2 || user.status === 6
                                  ? 'red'
                                  : user.status === 5
                                    ? 'green'
                                    : 'black',
                            }}
                          >{getServiceStatusLabel(user.status)}</div>
                        </div>
                      </td>

                      <td>
                        {user.status === 3 || user.status === 4? user.first_payment:user.status===5?user.second_payment:'...'}
                      </td>
                      <td>
                        <div className="actions">
                          <button className=" view" onClick={() => redirect(user._id)} >
                            <Eye />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {/* <tr>
                                <td>14-06-2023,  12:00 AM</td>
                                <td>12348</td>
                                <td>Hair Cut</td>
                                <td>
                                    <div className="status_action d-flex align-items-center">
                                        <div className="green">Completed</div>
                                    </div>
                                </td>
                                <td>
                                    COD
                                </td>
                                <td>
                                    <div className="actions">
                                        <button className=' view' onClick={redirect}>
                                            <Eye />
                                        </button>
                                    </div>
                                </td>
                            </tr> */}
                </tbody>
              </table>
            </div>
          </div >
        </>}
    </>
  );
}
