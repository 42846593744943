import React, { useEffect, useState } from 'react';
import { More, Star } from './Icons';

export default function Testimonial(props) {

    const [testimonial, setTestimonial] = useState([]);


    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < rating; i++) {
            stars.push(<Star key={i} />);
        }
        return stars;
    };

    const reviewDate = (timestamp) => {
        const date = new Date(timestamp);

        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();

        // Format the date as 'DD-MM-YYYY'
        const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
        return formattedDate;
    }

    useEffect(() => {
        setTestimonial(props.testimonial)
    }, [])

    console.log(testimonial, "Here from the Testimonials hhhhhhhhhhhhhhhh");
    return (
        <>
            {testimonial.map((e) => {
                return (
                    <div className="rating_outer_sec">
                        <div className="top_part d-flex justify-content-between">
                            <div className="user_dtl">
                                <div className="d-flex align-items-center gap-2">
                                    <div className="img_user">
                                        <img src="/user.jpg" alt="" className='img-fluid rounded-circle' />
                                    </div>
                                    <div className="d-grid">
                                        <div className="name pb-1">{e.full_name}</div>
                                        <div className="small_heading">1 review</div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-1 mt-2">
                                    <div className="rting_star d-flex">
                                        {renderStars(e.rating)}
                                    </div>
                                    <div className="small_heading">
                                    {reviewDate(e.timestamp)}
                                    </div>
                                </div>
                            </div>
                            <div className="other_opt">
                                <div class="dropdown">
                                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <More />
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                        <li><a class="dropdown-item" href="#">Edit</a></li>
                                        <li><a class="dropdown-item" href="#">Delete</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="small_heading mt-3"><p>
                            {e.review}
                        </p></div>
                    </div>
                )

            })}

        </>
    )
}
