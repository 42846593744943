import React from 'react'

const Input = ({ label, htmlFor, name, value, type, placeholder, required, icon, onChange, className, accept, loader, autoComplete, ID, cssClass, ...props }) => {

    const handleChange = (e) => {
        onChange(e); // Call the provided onChange prop
    }

    return (
        <div className={"form-group " + className}>
            <label htmlFor={htmlFor}>{label}</label>
            <input type={type} name={name}
                className={cssClass} placeholder={`${placeholder} ${required ? '*' : ''}`} {...props} id={htmlFor} value={value} onChange={handleChange} />
            <span>
                {icon}
            </span>
        </div>
    )
}
export default Input;

