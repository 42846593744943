import React from "react";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header";
import Notification from "../common/Notification";
import Dashboard from "./Dashboard";
import Customer_managerment from "./Customer_managerment";
import Customer_details from "./Customer_details";
import Provider_management from "./Provider_management";
import { Route, Routes } from "react-router-dom";
import Provider_details from "./Provider-details";
import Service_request from "./Service_request";
import Service_details from "./Service_details";
import Payment_management from "./Payment_management";
import Rating_management from "./Rating_management";
import Payment_details from "./Payment_details";
import Privacy_policy from "./Privacy_policy";
import Terms from "./Terms";
import Disclaimer from "./Disclaimer";
import Login_Signup from "./Login_Signup";
import Modal from "../common/Modal";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import Category from "./Category_management";
import PageNotFound from "./PageNotFound";
import RegionManagement from "./RegionManagement";
import IdType from "./IdTypes";
import PaymentPercentage from "./PaymentPercentage";
import AnnouncementList from "./AnnouncementList";
import Announcements from "./Announcements";
import AddressManagement from "./AddressManagement";
import AddressDetail from "./AddressDetail";

export default function Main() {
  let auth = localStorage.getItem("x_token");
  return (
    <>
      {auth ? (
        <>
          <Sidebar />
          <main className="main" id="main">
            <section className="section">
              <div className="row m-0">
                <div className="col-lg-9 col-md-9 ps-0 pe-0 setBorder">
                  <Header />
                  <div className="all_comp">
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route
                        path="/customer-management"
                        element={<Customer_managerment />}
                      />
                      <Route
                        path="/customer-details/:id"
                        element={<Customer_details />}
                      />
                      <Route
                        path="/provider-management/"
                        element={<Provider_management />}
                      />
                      <Route
                        path="/provider-address-management"
                        element={<AddressManagement />}
                      />
                       <Route
                        path="/provider-address-management/:id"
                        element={<AddressDetail />}
                      />
                       <Route
                        path="/region-management/"
                        element={<RegionManagement />}
                      />
                      <Route
                        path="/provider-details/:id"
                        element={<Provider_details />}
                      />
                      <Route
                        path="/category-management"
                        element={<Category />} />
                      <Route
                        path="/service-request"
                        element={<Service_request />}
                      />
                      <Route
                        path="/send-announcement"
                        element={<Announcements />}
                      />
                      <Route
                        path="/service-details/:id"
                        element={<Service_details />}
                      />
                      <Route
                        path="/all-id-types"
                        element={<IdType />}
                      />
                      
                      <Route
                        path="/payment-management"
                        element={<Payment_management />}
                      />
                       <Route
                        path="/payment-details/:id"
                        element={<Payment_details />}
                      />
                      <Route
                        path="/rating-management"
                        element={<Rating_management />}
                      />
                      <Route
                        path="/announcements"
                        element={<AnnouncementList />}
                      />
                      <Route
                        path="/initial-amount"
                        element={<PaymentPercentage />}
                      />
                      <Route
                        path="/privacy-policy"
                        element={<Privacy_policy />}
                      />
                      <Route path="/terms" element={<Terms />} />
                      <Route path="/disclaimer" element={<Disclaimer />} />
                      <Route path="/my-profile" element={<Profile />} />
                      <Route path="*" element={<PageNotFound />} />
                      <Route
                        path="/change-password"
                        element={<ChangePassword />}
                      />
                    </Routes>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 ps-0">
                  <div className="norification">
                    <div className="notify_title">Notification</div>
                    <Notification />
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Modal />
        </>
      ) : (
        <Login_Signup />
      )}
    </>
  );
}
