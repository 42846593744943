import Input from '../common/Input'
import Button from '../common/Button'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postRequest, setToken } from '../ApiFunction';
import { useDispatch } from 'react-redux';
import API from "../Api"
import { setUser } from '../redux/userSlice';

export default function Login_Signup() {
    const navigate = useNavigate();
    const dispatcher = useDispatch()
    const [data, setData] = useState({ email: "", password: "", new_password: "", confirm_password: "", otp: "" });
    const [forgot, setForgot] = useState(false);
    const [verify, setVerify] = useState(false);
    const [otp, setOtp] = useState(false);

    const handleInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };



    const postData = async (e) => {
        e.preventDefault();
        try {
            const result = await postRequest(API.LOGIN_ADMIN, data);
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                setToken(result.data.results.accessToken)
                dispatcher(setUser(result.data.results))
                localStorage.setItem('info', JSON.stringify(result.data.results))
                localStorage.setItem("token", result.data.results.accessToken);
                navigate("/");
                window.location.reload()
                toast.success(result.data.message);
            }
        } catch (error) {
            console.log(error.message)
        }
    };

    const forgotData = async (e) => {
        e.preventDefault();
        try {
            const result = await postRequest(API.FORGOT_PASSWORD, data);
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message);
                setOtp(true)
            }
        } catch (error) {
            console.log(error.message)
        }
    };

    const verifyData = async (e) => {
        e.preventDefault();
        try {
            const result = await postRequest(API.VERIFY_OTP, data);
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message);
                setVerify(true)
                setOtp(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    };

    const newData = async (e) => {
        e.preventDefault();
        try {
            const result = await postRequest(API.SET_NEW_PASSWORD, data);
            if (!result.data.status) {
                toast.error(result.data.message)
            } else {
                toast.success(result.data.message);
                setVerify(false)
                setOtp(false)
                setForgot(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    };



    return (
        <>
            <div className="container">
                <div className="row align-items-center h-100vh">
                    <div className="col-lg-5 col-md-5">
                        <div className="login_area">
                            <div className="logo"><img src="/MakeMe.png" className='img-fluid' /></div>
                            <p className='mt-2'>
                                {otp ?
                                    'Verification Otp'
                                    : verify ?
                                        'Set your new password'
                                        : !forgot ?
                                            'Sign in your account'
                                            :
                                            'Forgot your password'
                                }
                            </p>
                            <div className="mt-5">
                                {otp ?
                                    <a>
                                        <div className="group mt-4">
                                            <Input className={'mt-2'} type={'text'} label={'OTP'} placeholder={'enter your otp'} id="otp" name={'otp'} onChange={handleInput} value={data.otp}/>
                                        </div>
                                        <div className="site_btn mt-5">
                                            <Button text={'Verify'} disabled={data.otp === ''} onClick={verifyData} />
                                        </div>
                                    </a>
                                    :
                                    verify ?
                                        <a>
                                            <div className="group mt-4">
                                                <Input className={'mt-2'} type={'password'} label={'new password'} placeholder={'new password'} id="new_password" name={'new_password'} onChange={handleInput} value={data.new_password}/>
                                            </div>
                                            <div className="group mt-4">
                                                <Input className={'mt-2'} type={'password'} label={'confirm password'} placeholder={'confirm password'} id="confirm_password" name={'confirm_password'} onChange={handleInput} value={data.confirm_password}/>
                                            </div>
                                            <div className="site_btn mt-5">
                                                <Button text={'Submit'} disabled={data.new_password === '' || data.confirm_password === ''} onClick={newData} />
                                            </div>
                                        </a>
                                        :
                                        <>
                                            {!forgot ?
                                                <a>
                                                    <div className="group">
                                                        <Input className={'mt-2'} type={'email'} onChange={handleInput} placeholder={'abc@example.com'} name={'email'} label={'email'} value={data.email} />
                                                    </div>
                                                    <div className="group mt-4">
                                                        <Input className={'mt-2'} type={'password'} label={'password'} placeholder={'password'} id="password" name={'password'} onChange={handleInput} value={data.password} />
                                                    </div>
                                                    <div className="text-end mt-1 forget iconCursor" onClick={() => setForgot(true)}>
                                                        Forgot Password?
                                                    </div>
                                                    <div className="site_btn mt-5">
                                                        <Button text={'Submit'} disabled={data.email === '' || data.password === ''} onClick={postData} />
                                                    </div>
                                                </a>
                                                :
                                                <a>
                                                    <div className="group mt-4">
                                                        <Input className={'mt-2'} type={'email'} label={'email'} placeholder={'enter your email'} id="email" name={'email'} onChange={handleInput} />
                                                    </div>
                                                    <div className="text-end mt-1 forget iconCursor" onClick={() => setForgot(false)}>
                                                        Remember your password? Login.
                                                    </div>
                                                    <div className="site_btn mt-5">
                                                        <Button text={'Submit'} disabled={data.email === ''} onClick={forgotData} />
                                                    </div>
                                                </a>
                                            }
                                        </>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 mobile_hide">
                        <div className="center_img text-center">
                            <img src="/login-img.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
