import React, { useEffect, useState } from 'react';
import Card from '../common/Card';
import { ToastContainer, toast } from 'react-toastify';
import server from '../server';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../ApiFunction';
import API from "../Api"
export default function Dashboard() {
  const nav = useNavigate()
  const [data, setData] = useState({
    customer: '',
    provider: '',
    verificationRequest: '',
    customer_growth: 0,
    provider_growth: 0,
    verification: "",
    verification_growth: ""
  });

  const getData = async () => {
    try {
      const response = await getRequest(`${API.GET_DASHBOARD}`)
      if (!response.data.status) {
        if (response.data.code === 203) {
          localStorage.clear()
          nav('/login')
          toast.error(response.data.message)
          window.location.reload()
        } else {
          toast.error(response.data.message)
        }
      } else {
        var customer = response.data.results.customer.providerCount
        var customer_growth = parseFloat(response.data.results.customer.growth)
        var provider = response.data.results.provider.providerCount
        var provider_growth = parseFloat(response.data.results.provider.growth)
        var verification = response.data.results.verification.request
        var verification_growth = response.data.results.verification.growth
        setData({
          ...response.data.results,
          customer,
          provider,
          customer_growth,
          provider_growth,
          verification,
          verification_growth
        });;
      }
    } catch (error) {
      console.log(error.message)
    }
  }




  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="pagetitle">Dashboard</div>
      <div className="row mt-4">
          <Card Provider={'Provider'} number={data.provider} graph={`+${data.provider_growth}%`} />
        <Card Provider={'Customer'} number={data.customer} graph={`+${data.customer_growth}%`} />
        <Card
          Provider={'Verification requests'}
          number={data.verification}
          graph={`+${data.verification_growth}%`}
        />
      </div>
    </>
  );
}
