import { ChangeImage, EditICON } from "../common/Icons";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import server from "../server";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../ApiFunction";
import API, { ImageUrl } from "../Api"
import Loader from "../common/Loader";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/userSlice";

const Profile = () => {
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(true);
  const [showImage, setShowImage] = useState("");
  const [file, setFile] = useState("");
  const [data, setData] = useState({ name: "" });
  const nav = useNavigate()
  const dispatcher = useDispatch()

  const addData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getData = async () => {
    try {
      const result = await getRequest(API.GET_ADMIN_PROFILE);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        setData(result.data.results)
        dispatcher(setUser(result.data.results))
        localStorage.setItem('info', JSON.stringify(result.data.results))
        setLoad(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateUser = async () => {
    try {
      if (data.name === "") {
        toast.error("Name not allowed to be empty!");
      } else {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("file", file === "" ? data.profile_pic : file);
        const result = await postRequest(API.UPDATE_ADMIN_PROFILE, formData);
        if (!result.data.status) {
          if (result.data.code === 203) {
            localStorage.clear()
            nav('/login')
            window.location.reload()
        }else{
            toast.error(result.data.message)
        }
        } else {
          setLoad(false)
          setEdit(false);
          getData();
          toast.success("Successfully saved!");

        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Something went wrong");
    }
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleImage = (e) => {
    setShowImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <div className="pagetitle">Hello, Admin!</div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex gap-2 justify-content-end align-items-center w-100">
            {edit ? null : (
              <div className="editBtn  d-flex gap-1" onClick={handleEdit}>
                <EditICON />
                Edit
              </div>
            )}
          </div>
        </div>
      </div>
      {load ?
        <Loader />
        :
        <>
          <div className="whiteProfilePage">
            <div className="profileDetails">
              {edit ? (
                <>
                  {showImage === "" ? (
                    <>
                      <label htmlFor="profilePic">
                        <div className="imageSet">

                          {data.profile_pic !== "" ? (
                            <img src={ImageUrl + data.profile_pic} alt="profileImage" className="adminProfilePic" />
                          ) : (
                            <img src="/images/blank_pic.png" alt="profileImage" className="adminProfilePic" />
                          )}

                          <div className="imageChnage"> <ChangeImage /></div>
                        </div>
                      </label>
                      <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }} accept="image/*"
                      />
                    </>
                  ) : (
                    <>
                      <label htmlFor="profilePic">
                        <div className="imageSet">
                          <img src={showImage} alt="profileImage" className="adminProfilePic" />
                          <div className="imageChnage">
                            <ChangeImage />
                          </div>
                        </div>
                      </label>
                      <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }} accept="image/*"
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {data.profile_pic !== "" ? (
                    <img
                      src={ImageUrl + data.profile_pic}
                      alt="profileImage"
                      className="adminProfilePic"
                    />
                  ) : (
                    <img
                      src="/images/blank_pic.png"
                      alt="profileImage"
                      className="adminProfilePic"
                    />
                  )}
                </>
              )}

              <div className="details">
                <div className="inputSet">
                  <h6>Name</h6>
                  {!edit ? (
                    <h5>{data.name}</h5>
                  ) : (
                    <input type="text" placeholder="Enter you name..." value={data.name} name="name" id="name" onChange={addData} />
                  )}
                </div>
                <div className="inputSet">
                  <h6>Email Address</h6>
                  <h5>{data.email}</h5>
                </div>
              </div>
            </div>
          </div>
          {edit ? (
            <div
              className="modal_btns"
              style={{ justifyContent: "flex-start" }}
              onClick={updateUser}
            >
              <button className="purple">Save</button>
            </div>
          ) : null}
        </>}
    </>
  );
};

export default Profile;
