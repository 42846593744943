import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../ApiFunction";
import API from "../Api"


export default function Announcements() {

    const nav = useNavigate();
    const [load, setLoad] = useState(true);
    const [selectedType, setSelectedType] = useState(1);
    const [customer_provider, set_customer_provider] = useState([])
    const [selectAll, setSelectAll] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [err, setErr] = useState(false)
    const [editableAnnouncement, setEditableAnnouncement] = useState("");
    const [editMode, setEditMode] = useState(false);




    const handleTypeChange = (event) => {
        if (event.target.value == '1') {
            setSelectedType(1);
            setSelectAll(false);
            setUserIds([]);
            setErr(false);
            setEditMode(false);

        } else if (event.target.value == '0') {
            setSelectedType(0);
            setSelectAll(false);
            setUserIds([]);
            setErr(false);
            setEditMode(false);
        }
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const updatedProviders = customer_provider.map((provider) => ({
            ...provider,
            selected: isChecked,
        }));
        set_customer_provider(updatedProviders);

        if (isChecked) {
            const allUserIds = customer_provider.map((provider) => provider._id);
            setUserIds(allUserIds);
        } else {
            setUserIds([]);
        }
    };


    const get_customer_provider = async () => {
        try {

            const result = await getRequest(`${API.GET_CUSTOMER_TYPE}?type=${selectedType}`);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                set_customer_provider(result.data.data);
                setLoad(false);
            }

        } catch (error) {
            console.log(error.message)
        }
    };


    const handleCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;

        const updatedProviders = customer_provider.map((provider) => {
            if (provider._id === id) {
                return { ...provider, selected: isChecked };
            }
            return provider;
        });

        set_customer_provider(updatedProviders);

        if (isChecked) {
            // Checkbox is checked, add userId to the array
            setUserIds((prevUserIds) => [...prevUserIds, id]);
        } else {
            // Checkbox is unchecked, remove userId from the array
            setUserIds((prevUserIds) => prevUserIds.filter((userId) => userId !== id));
        };

    };

    const handleSendAnnouncement = async () => {
        try {
            if (userIds.length === 0) {
                setErr(true)
            } else {
                const result = await postRequest(`${API.SEND_ANNOUNCEMENT}`, { userId: userIds, annoucement: editableAnnouncement });
                if (!result.data.status) {
                    if (result.data.code === 203) {
                        localStorage.clear()
                        nav('/login')
                        window.location.reload()
                    } else {
                        toast.error(result.data.message)
                    }
                } else {
                    setLoad(false);
                    toast.success(result.data.message);
                    setEditableAnnouncement("")
                    setEditMode(false);
                }
            }
        } catch (error) {
            console.log(error.message)
        }
    }


    useEffect(() => {
        get_customer_provider();
    }, [selectedType]);



    return (
        <>
            <div className="top_bar row align-items-center mt-2 mb-2">
                <div className="col-lg-6">
                    <div className="pagetitle">Announcements
                    </div>
                </div>
                <div className='col-lg-5 offset-md-1'>
                    <div className="select_drop">
                        <select className="form-select form-control" onChange={handleTypeChange} >
                            <option value="1">Provider</option>
                            <option value="0">Customer</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="outer_div mt-4">
                <div className={`name ${editMode ? "editable" : ""}`} onClick={() => setEditMode(true)}>
                    <textarea
                        rows="3"
                        cols="40"
                        style={{ resize: "none" }}
                        id="announcementMessage"
                        className="form-control"
                        value={editableAnnouncement}
                        onChange={(e) => setEditableAnnouncement(e.target.value)}
                        readOnly={!editMode}
                    />
                </div>
            </div>
            <div className="selector_top mt-5">
                <div className="form_check checkboxes">
                    <input
                        className="styled-checkbox"
                        id="styled-checkbox-select-all"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                    />
                    <label htmlFor="styled-checkbox-select-all">Select All</label>
                </div>
                <div className="purple_btn mt-4">
                    <button className="btns" onClick={handleSendAnnouncement} disabled={editableAnnouncement===''}>
                        Send
                    </button>
                </div>

            </div>
            <div className="outer_div mt-4">
                <ul className="unstyled checkboxes">
                    <li className='d-flex justify-content-between'>
                        <label>Email</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label>Full Name</label>
                        <label>Is Verified User</label>
                    </li>
                </ul>
                {customer_provider.map((e, i) => (
                    <ul className="unstyled checkboxes" key={i}>
                        <li className='d-flex justify-content-between'>
                            <input
                                className="styled-checkbox"
                                id={`styled-checkbox-${e._id}`}
                                type="checkbox"
                                value={e._id}
                                checked={e.selected}
                                onChange={(event) => handleCheckboxChange(event, e._id)}
                            />
                            <label htmlFor={`styled-checkbox-${e._id}`}>{e.email}</label>
                            <label>{e.full_name}</label>
                            <label>{e.isEmailVerified ? 'Verified' : 'Not Verified'}</label>
                        </li>
                    </ul>
                ))}
            </div>
            <div>
                {err && userIds.length === 0 ? <div className="errText">Please Select Atlease One User to Send Announcement</div> : null}
            </div>
        </>
    )
}
