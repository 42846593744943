import React, { useEffect, useState } from "react";
import { Back, Delete, Ok } from "../common/Icons";
import {
    NavLink,
    useParams,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../ApiFunction";
import API from "../Api"
import Loader from "../common/Loader";
import axios from "axios";

export default function AddressDetail() {
    const addressId = useParams();
    const [load, setLoad] = useState(true);
    const nav = useNavigate();
    const [detailObj, setDetailObj] = useState({});
    const [searchResult, setSearchResult] = useState(
        {
            address_name: "",
            country: "",
            city: "",
            state: "",
            postal_code: "",
            selected_region: ""
        }
    )


    const getData = async () => {
        try {
            const result = await getRequest(`${API.GET_ADDRESS_DETAILS}?id=${addressId.id}`)
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                setDetailObj(result.data.data)
                setLoad(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };



    const verifyAddress = async () => {
        try {
            const result = await postRequest(`${API.VERIFY_ADDRESS}`, detailObj)
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                nav('/provider-address-management')
                toast.success(result.data.message)
            }
        } catch (error) {
            console.log(error.message)
        }
    };


    const deleteAddress = async () => {
        try {
            const result = await postRequest(`${API.DELETE_ADDRESS}`, detailObj)
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                nav('/provider-address-management')
                toast.success(result.data.message)
            }
        } catch (error) {
            console.log(error.message)
        }
    };

    // function findAddressComponent(components, type) {
    //     const foundComponent = components.find((component) =>
    //         component.types.includes(type)
    //     );
    //     return foundComponent ? foundComponent.short_name : null;
    // }

    // const handleSearch = async () => {
    //     try {
    //         const geocodingResponse = await axios.get(
    //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${detailObj.region.coordinates[1]},${detailObj.region.coordinates[0]}&key=AIzaSyBOVIOujus2fwzdEJB9Qdgs339eH6vECpQ`
    //         );
    //         console.log(geocodingResponse,'eveevevee')
    //         // Extract relevant address information from the API response
    //         const addressComponents = geocodingResponse.data.results[1].address_components;
    //         const formattedAddress = geocodingResponse.data.results[1].formatted_address;
    //         setSearchResult(
    //             {
    //                 address_name: formattedAddress,
    //                 country: findAddressComponent(addressComponents, "country"),
    //                 city: findAddressComponent(addressComponents, "sublocality_level_1"),
    //                 state: findAddressComponent(
    //                     addressComponents,
    //                     "administrative_area_level_1"
    //                 ),
    //                 postal_code: findAddressComponent(addressComponents, "postal_code"),
    //                 selected_region: findAddressComponent(addressComponents, "locality"),
    //             }
    //         )
    //     } catch (error) {
    //         console.log(error.message)
    //         toast.error(error.message)
    //     }
    // }





    useEffect(() => {
        getData();
        window.scroll(0, 0)
    }, []);



    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <NavLink to={"/provider-address-management"}>
                        <div className="pagetitle">
                            <span>  <Back />  </span>  Address details
                        </div>
                    </NavLink>
                </div>
                <div className="col-lg-6 text-end">

                </div>
            </div>
            {load
                ?
                <Loader /> :
                <>
                    <div className="outer_div mt-4">
                        <p>If address correct, verify it. If details are missing, delete them with the delete button.</p>
                        <hr></hr>
                        <div className="row">
                            <div className="col-lg-2 col-md-2">
                                <img src="/images/location.svg" alt="profileImage" className="customerProfilePic w-100" />
                            </div>
                            <div className="col-lg-10 col-md-10">
                                <ul className="profile_list p-0">
                                    {/* <div className="small_heading">Provider address. */}
                                        {/* <button className='addressSearchBtn mt-2' onClick={handleSearch}>Search</button> */}
                                    {/* </div> */}
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">User name</div>
                                            {detailObj.user}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">User email</div>
                                            {detailObj.email}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">User Contact</div>
                                            {detailObj.contact===""?
                                            'Not added'
                                            :
                                            <>
                                            {detailObj.country_code + " " + detailObj.contact}
                                            </>}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Address</div>
                                            {detailObj.region && detailObj.region.address_name}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">State</div>
                                            {detailObj.region && detailObj.region.state}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">City</div>
                                            <div className="name mt-2">
                                                {detailObj.region && detailObj.region.city}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">postal Code</div>
                                            <div className="name mt-2">
                                                {detailObj.region && detailObj.region.postal_code}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Selected Region</div>
                                            <div className="name mt-2">
                                                {detailObj.selected_region}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="modal_btns d-flex gap-3 justify-content-start">
                                    <button className="purple" data-bs-toggle="modal" data-bs-target="#address_modal">Verify Now</button>
                                    <button className="purple" data-bs-toggle="modal" data-bs-target="#delete_modal">Delete</button>
                                </div>
                            </div>
                            {/* <div className="col-lg-5 col-md-5">
                                <ul className="profile_list p-0">
                                    <div className="small_heading">Search result displayed here.</div>
                                    <br/>                                
                                    <li>
                                        <div className="detail_wrapper mt-4">
                                            <div className="small_heading">Address</div>
                                            {searchResult.address_name}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">State</div>
                                            {searchResult.state}
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">City</div>
                                            <div className="name mt-2">
                                                {searchResult.city}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">postal Code</div>
                                            <div className="name mt-2">
                                                {searchResult.postal_code}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="detail_wrapper">
                                            <div className="small_heading">Region</div>
                                            <div className="name mt-2">
                                                {searchResult.selected_region}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                    <div class="modal fade" id="address_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-sm modal-dialog-centered">
                            <div class="modal-content">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div class="modal-body text-center">
                                    <div className="modal_icon red">
                                        <Ok />
                                    </div>
                                    <div className="modal_msg">
                                        Are you verifying this address? Do you want to accept it on your side?                            </div>
                                    <div className="modal_btns ">
                                        <button className='purple' onClick={verifyAddress} data-bs-dismiss="modal" aria-label="Close">Yes</button>
                                        <button data-bs-dismiss="modal">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="delete_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-sm modal-dialog-centered">
                            <div class="modal-content">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div class="modal-body text-center">
                                    <div className="modal_icon red">
                                        <Delete />
                                    </div>
                                    <div className="modal_msg">
                                        Are you verifying this address? Do you want to delete this address?
                                    </div>
                                    <div className="modal_btns ">
                                        <button className='purple' onClick={deleteAddress} data-bs-dismiss="modal" aria-label="Close">Yes</button>
                                        <button data-bs-dismiss="modal">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
}
