import React, { useEffect, useState } from "react";
import { Archived, Search, UnArchived } from '../common/Icons'
import Search_input from '../common/Search_input'
import { NavLink, useNavigate } from "react-router-dom";
import moment from 'moment';
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../ApiFunction";
import API from "../Api"
import Loader from "../common/Loader";

export default function AnnouncementList() {
    const [load, setLoad] = useState(true)
    const nav = useNavigate();
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [obj, setObj] = useState({})


    const getAll = async () => {
        try {
            const result = await getRequest(`${API.GET_ALL_ANNOUNCEMENT_HISTORY}?search=${search}`);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                setUsers(result.data.data);
                setLoad(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    }


    const searchType = async (e) => {
        setSearch(e.target.value);
    };




    const archivedNow = async (id) => {
        try {
            const result = await postRequest(`${API.ARCHIVED_UN_ARCHIVED}`, obj)
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                } else {
                    toast.error(result.data.message)
                }
            } else {
                getAll();
                toast.success(result.data.message)
            }
        } catch (error) {
            console.log(error.message)
        }
    };




    useEffect(() => {
        getAll()
    }, [search]);

    return (
        <>
            <div className="row">
                <p className="announcement-send w-100 d-flex justify-content-end">
                    <NavLink to="/send-announcement">
                        <button className="editBtn">
                            send announcement
                        </button>
                    </NavLink>
                </p>
            </div>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Announcement History
                    </div>
                </div>
                <div className="col-lg-5 offset-lg-1">
                    <Search_input
                        placeholder={'Search here'}
                        value={search}
                        onChange={searchType} // Ensure that the onChange event is connected.
                        icon={<Search />}
                        className='search_input'
                    />
                </div>

            </div>
            {load ?
                <Loader />
                :
                <>
                    <div className="row mt-3">
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colSpan="4">
                                        <th>S.No</th>
                                        <th>Message</th>
                                        <th>Status</th>
                                        <th>Date of announcement</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length === 0 ?
                                        <>
                                            <tr>
                                                <td colSpan="4">No announcement found.</td>
                                            </tr>
                                        </>
                                        :
                                        <>
                                            {users.map((user, index) => (
                                                <tr key={user.id}>
                                                    <td>{index + 1} </td>
                                                    <td>{user.message} </td>
                                                    <td>
                                                        {!user.isArchived
                                                            ?
                                                            <span className="badge bg-success">Active</span>
                                                            :
                                                            <span className="badge bg-secondary">Archived</span>
                                                        }
                                                    </td>
                                                    <td>{moment(user.createdAt).format('L')}</td>
                                                    <td>
                                                        <div className="actions">
                                                            {!user.isArchived
                                                                ? <button className="archive" data-bs-toggle="modal" data-bs-target="#archived_modal" >
                                                                    <div data-bs-toggle="tooltip" data-bs-placement="top" type="Activate region" onClick={() => { setObj(user) }}>
                                                                        <Archived />
                                                                    </div>
                                                                </button> :
                                                                <button className="archive" data-bs-toggle="modal" data-bs-target="#archived_modal" >
                                                                    <div data-bs-toggle="tooltip" data-bs-placement="top" type="Activate region" onClick={() => { setObj(user) }}>
                                                                        <UnArchived />
                                                                    </div>
                                                                </button>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </tbody>

                            </table>
                        </div>
                    </div>
                </>}
            <div class="modal fade" id="archived_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        <div class="modal-body text-center">
                            <div className="modal_icon red">
                                <Archived />
                            </div>
                            <div className="modal_msg">
                                Do you want to {obj.isArchived ? 'Activate' : 'Archived'} this announcement from users side?
                            </div>
                            <div className="modal_btns ">
                                <button className="purple" data-bs-dismiss="modal"
                                    aria-label="Close" onClick={archivedNow}>Yes</button>
                                <button data-bs-dismiss="modal">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}