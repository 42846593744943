import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Back } from "../common/Icons";
import Input from "../common/Input";
import server from "../server";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import API from "../Api"
import { getRequest } from '../ApiFunction';
import moment from "moment";



export default function Payment_details() {
  let { id } = useParams();
  const nav = useNavigate();
  const [serviceSelected, setServiceSelected] = useState([]);
  const [serviceDetails, setServiceDetails] = useState({});
  const [address, setAddress] = useState({
    address_name: "",
    city: "",
    country: "",
    postal_code: "",
    state: "",
  });

  const get_payment_details = async () => {
    try {
      const result = await getRequest(`${API.GET_PAYMENT_DETAILS}?paymentId=${id}`)
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          toast.error(result.data.message)
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        setServiceDetails(result.data.data);
        setAddress({
          address_name: result.data.data.booking_address.address_name,
          city: result.data.data.booking_address.city,
          country: result.data.data.booking_address.country,
          postal_code: result.data.data.booking_address.postal_code,
          state: result.data.data.booking_address.state,
        });
        setServiceSelected(result.data.data.service_selected);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getServiceStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Pending";
      case 2:
        return "Cancelled";
      case 3:
        return "Completed";
      case 4:
        return "Partial";
      case 5:
        return "Completed";
      case 6:
        return "Cancelled";
      default:
        return ""; // Default label, you can change it to another label or remove it
    }
  };


  useEffect(() => {
    get_payment_details();
  }, []);

  return (
    <>
      <div className="top_bar row align-items-center mb-4 mt-3">
        <div className="col-lg-12">
          <div className="pagetitle">
            <NavLink to={"/payment-management"}>
              <div className="d-flex align-items-center">
                <span>
                  <Back />
                </span>
                Payment Details
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="outer_div">
        <div className="row">
          <div className="col-lg-4 border_right">
            <div className="sub_title">Customer details</div>
            <ul className="p-0 single_column mt-4">
              <li>
                <div className="detail_wrapper">
                  <div className="small_heading">Full Name</div>
                  <div className="name mt-2">
                    {serviceDetails.customer_name}
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_wrapper">
                  <div className="small_heading">Email Address</div>
                  <div className="name mt-2">
                    {serviceDetails.customer_email}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-8 ps_100">
            <div className="sub_title">Provider details</div>
            <ul className="p-0 single_column mt-4">
              <li>
                <div className="detail_wrapper">
                  <div className="small_heading">Full Name</div>
                  <div className="name mt-2">
                    {serviceDetails.provider_name}
                  </div>
                </div>
              </li>
              <li>
                <div className="detail_wrapper">
                  <div className="small_heading">Email Address</div>
                  <div className="name mt-2">
                    {serviceDetails.provider_email}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-7">
            <div className="light_gray">
              <div className="sub_title">Booking details</div>
              <ul className=" p-0 two_column mt-4">
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Booking ID</div>
                    <div className="name mt-2">{serviceDetails.bookingId}</div>
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Selected services</div>
                    {serviceSelected.map((e) => (
                      <div className="name mt-2">{e.add_title}</div>
                    ))}
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Number of persons</div>
                    <div className="name mt-2">
                      {serviceDetails.number_of_peoples}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Address</div>
                    {/* {console.log(address , "Addresssss")} */}
                    <div className="name mt-2">{address.address_name},{address.city},{address.state},{address.country},{address.postal_code}</div>
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Date & TIme</div>
                    <div className="name mt-2">
                      {moment(serviceDetails.time_of_booking).format('DD-MM-YYYY, hh:mm a')}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Service status</div>
                    <div className="name mt-2"
                        style={{
                          color: serviceDetails.status === 0 || serviceDetails.status === 1 || serviceDetails.status === 4
                            ? 'orange'
                            : serviceDetails.status === 2 || serviceDetails.status === 6
                              ? 'red'
                              : serviceDetails.status === 3 || serviceDetails.status === 5
                                ? 'green'
                                : 'black',
                        }}
                    >
                      {getServiceStatusLabel(serviceDetails.status)}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 ">
            <div className="skyblue">
              <div className="sub_title">Customer Price details</div>
              <ul className="p-0 single_column mt-4">
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Sub total</div>
                    <div className="name mt-2">SAR {serviceDetails.sub_total}</div>
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading mb-2">Platform Charges</div>
                    <input type="text" disabled value={`SAR ${serviceDetails.current_platform_percentage}%`} />
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Total</div>
                    <div className="name mt-2">SAR {serviceDetails.total_price}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 mt-4">
            <div className="skyblue">
              <div className="sub_title">Service Provider Price details</div>
              <ul className="p-0 single_column mt-4">
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Sub total</div>
                    <div className="name mt-2">SAR {serviceDetails.sub_total}</div>
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading mb-2">Platform Charges</div>
                    <input type="text" disabled value={`SAR ${serviceDetails.provider_platform_charges} Rial`} />
                  </div>
                </li>
                <li>
                  <div className="detail_wrapper">
                    <div className="small_heading">Total</div>
                    <div className="name mt-2">SAR {serviceDetails.total_price}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="green_btn mt-4">
        <button className="btns">Send money to Provider</button>
      </div> */}
    </>
  );
}
