import React, { useEffect, useState } from "react";
import Profile_dropdown from "./Profile_dropdown";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import server from "../server";
import { useNavigate } from "react-router-dom";
import { setUser } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import API from "../Api"
import { getRequest } from "../ApiFunction";


export default function Header() {
  const [isClassApplied, setIsClassApplied] = useState("sidetoggle");
  const [data, setData] = useState({});
  const [load, setLoad] = useState(true);
  const nav = useNavigate()
  const dispatch = useDispatch()
  
  const toggleClass = () => {
    const body = document.body;
    body.classList.toggle("sidetoggle");
    setIsClassApplied((prevState) => !prevState);
  };

  const getData = async () => {
    try {
      const result = await getRequest(API.GET_ADMIN_PROFILE);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          toast.error(result.data.message)
        }else{
          toast.error(result.data.message)
        }
      } else {
        setData(result.data.results)
        dispatch(setUser(result.data.results))
        localStorage.setItem('info', JSON.stringify(result.data.results))
        setLoad(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
    dispatch(setUser(JSON.parse(localStorage.getItem('info'))))
  }, []);
  return (
    <>
      <header className="header">
        <div className="toggle_logo">
          <div className="toggle_btn">
            <button onClick={toggleClass}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </button>
          </div>
          <div className="inner_logo">
            <img src="/MakeMe.png" alt="" className="img-fluid" />
          </div>
        </div>
        {load ? 
        'Loading...'
        : <Profile_dropdown data={data} />}
      </header>
    </>
  );
}
