import React from 'react'
import { Annoucement, Disclaimer, Home, Payment, Service, User, Star, RegionIcon, IDIcon, Payment2, Address } from './Icons'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function Sidebar() {
    
    return (
        <>
            <aside id='sidebar' className='sidebar'>
                <ul className='sidenav' id='sidenav'>
                    <div className="menu_title">Dashboard</div>
                    <li className="nav-item ">
                        <NavLink to="/" className="nav-link">
                            <span><Home /></span>
                            <span>Home</span>
                        </NavLink>
                    </li>
                    <div className="menu_title">User Management</div>
                    <li className="nav-item">
                        <NavLink to="/customer-management" className="nav-link">
                            <span><User /></span>
                            <span>Customer</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/provider-management" className="nav-link">
                            <span><User /></span>
                            <span>Provider</span>
                        </NavLink>
                    </li> 
                    <li className="nav-item">
                        <NavLink to="/provider-address-management" className="nav-link">
                            <span><Address /></span>
                            <span>Addresses</span>
                        </NavLink>
                    </li>                    
                    <div className="menu_title">Management</div>
                    <li className="nav-item">
                        <NavLink to="/category-management" className="nav-link">
                            <span><User /></span>
                            <span>Category</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/service-request" className="nav-link">
                            <span><Service /></span>
                            <span>Services Request</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/region-management" className="nav-link">
                            <span><RegionIcon /></span>
                            <span>Regions</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/all-id-types" className="nav-link">
                            <span><IDIcon /></span>
                            <span>ID Types</span>
                        </NavLink>
                    </li> 
                    <li className="nav-item">
                        <NavLink to="/payment-management" className="nav-link">
                            <span><Payment /></span>
                            <span>Payments</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/initial-amount" className="nav-link">
                            <span><Payment2 /></span>
                            <span>Platform fee & Initial Amount</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/rating-management" className="nav-link">
                            <span><Star /></span>
                            <span>Ratings</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/announcements" className="nav-link">
                            <span><Annoucement /></span>
                            <span>Announcements</span>
                        </NavLink>
                    </li>
                    <div className="menu_title">Content Management</div>
                    <li className="nav-item">
                        <NavLink to="/privacy-policy" className="nav-link">
                            <span><Service /></span>
                            <span>Privacy Policy</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/terms" className="nav-link">
                            <span><Service /></span>
                            <span>Terms & conditions</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/disclaimer" className="nav-link">
                            <span><Disclaimer /></span>
                            <span>Disclaimer</span>
                        </NavLink>
                    </li>
                </ul>
            </aside>
        </>
    )
}


