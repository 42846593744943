import React, { useEffect, useState } from "react";
import Search_input from "../common/Search_input";
import { Delete, EditICON,Search } from "../common/Icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API, { ImageUrl } from "../Api"
import { getRequest, postRequest } from "../ApiFunction";

const Category = () => {
  const [page, setPage] = React.useState(0);
  const [load, setLoad] = useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = useState("");
  const nav = useNavigate();
  const token = localStorage.getItem("token");
  const [totalDoc, setTotalDoc] = useState("");
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const [showImage, setShowImage] = useState("")
  const [file, setFile] = useState(null)
  const [id, setId] = useState('')
  const [obj, setObj] = useState({ type: '', image: '' })



  const Create = async () => {
    try {
      if (obj.type === "") {
        toast.error("Category name is required!")
      } else if (showImage === "") {
        toast.error("Category image is required!")
      } else {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', obj.type);
        const result = await postRequest(`${API.CREATE_CATEGORY}`, formData)
        if (!result.data.status) {
          if (result.data.code === 203) {
            localStorage.clear()
            nav('/login')
            window.location.reload()
          } else {
            toast.error(result.data.message)
          }
        } else {
          fetchData()
          toast.success(result.data.message)
          setEdit(false);
          setShowImage("")
          setObj({ type: '', image: '' })
          setCreate(false)
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleImage = (e) => {
    setShowImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };


  const addData = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };




  const fetchData = async () => {
    try {
      const result = await getRequest(`${API.GET_ALL_CATEGORY}?pagenumber=${page}&limit=${rowsPerPage}&search=${search}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        setUsers(result.data.data);
        setTotalDoc(result.data.totalDocuments);
        setLoad(false)
      }
    } catch (error) {
      console.log(error.message)
    }
  };


  const searchType = async (e) => {
    setSearch(e.target.value);
  };

  const updateCategory = async () => {
    try {
      const formData = new FormData();
      formData.append("type", obj.type);
      formData.append("file", showImage !== "" ? file : obj.image);
      formData.append("_id", obj._id);
      const result = await postRequest(`${API.EDIT_SERVICE_CATEGORY}`, formData)
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        fetchData()
        toast.success(result.data.message)
        setShowImage("")
        setCreate(false)
        setObj({ type: '', image: '' })
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Something went wrong");
    }
  };





  const delete_service_category = async () => {
    try {
      const result = await postRequest(`${API.DELETE_SERVICE_CATEGORY}`, { id: id });
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
        toast.error(result.data.message)
      } else {
        fetchData()
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  }


  const editCategory = (user) => {
    setObj(user);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, search]);


  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <Search_input placeholder="Search here" icon={<Search />} className="search_input" onChange={searchType} />
        </div>
        <div className="col-lg-4 offset-lg-2">
          <div className="editBtn" style={{ width: "200px" }} data-bs-toggle="modal" data-bs-target="#create_category_modal" onClick={() => setCreate(true)} >
            Create Category
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="table-responsive">
          <table className="table table-lg">
            <thead className="table-light">
              <tr colspan="4">
                <th>S.No</th>
                <th>Icon</th>
                <th>Category name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user._id}>
                  <td>{index + 1}</td>
                  <td>{user.image !== '' ? (
                    <img src={ImageUrl + user.image} alt="icon" style={{ width: "30px", height: "30px", borderRadius: '40px' }} />
                  ) : (
                    <img src="/Defalt_category.png" style={{ width: "30px", height: "30px", borderRadius: "40px" }} alt="icon" />
                  )}</td>
                  <td>{user.type}</td>
                  <td>
                    <div className="actions">
                      <button className="edit" data-bs-toggle="modal" data-bs-target="#update_category_modal" onClick={() => editCategory(user)}  >
                        <EditICON />
                      </button>
                      <button className="delete" data-bs-toggle="modal" data-bs-target="#category_delete_modal" >
                        <div data-bs-toggle="tooltip" data-bs-placement="top" type="Delete User" onClick={() => { setId(user._id) }} >
                          <Delete />
                        </div>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* edit category modal from here */}
      <div class="modal fade" id="update_category_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body text-center">
              <div className="modal_msg">You can update this category</div>
              <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <input type="text" placeholder="type" style={{ marginBottom: "10px", marginTop: "10px" }} id="type" name="type" value={obj.type} onChange={addData} />
              </div>
              <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <input type="file" style={{ marginBottom: "10px", marginTop: "10px" }} onChange={handleImage} accept="image/*" />
              </div>
              <div className="modal_btns ">
                <button data-bs-dismiss="modal">Cancel</button>
                <button className="purple" onClick={updateCategory} data-bs-dismiss="modal" aria-label="Close" >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create category modal from here */}
      <div class="modal fade" id="create_category_modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body text-center">
              <div className="modal_msg">Create new category from here</div>
              <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <input type="text" placeholder="new category" name="type" id="type" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.type} onChange={addData} />
              </div>

              {showImage !== "" ?
                <div>
                  <p>Your selected image</p>
                  <img src={showImage} style={{ maxWidth: "50%" }} />
                </div>
                :
                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <label htmlFor="inputFile" className="w-100 text-left" style={{ cursor: "pointer" }}>
                    <p className="m-0">Please choose your image</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                      <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    </svg>
                  </label>
                  <input id="inputFile" type="file" style={{ display: "none", marginBottom: "10px", marginTop: "10px" }} name="image" onChange={handleImage} accept="image/*" />
                </div>
              }
              {!create && showImage === "" ?
                <div className="errText" >Please select your image its required!</div>
                : !create && obj.type === "" ?
                  <div className="errText" >Category name is required!</div>
                  : null
              }
              <div className="modal_btns ">
                <button data-bs-dismiss="modal">Cancel</button>
                {showImage !== "" && obj.type !== "" ?
                  <button className="purple" onClick={Create} data-bs-dismiss="modal" aria-label="Close">
                    Submit
                  </button>
                  :
                  <button className="purple" onClick={Create} >
                    Submit
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete modal from here */}
      <div class="modal fade" id="category_delete_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            <div class="modal-body text-center">
              <div className="modal_icon red">
                <Delete />
              </div>
              <div className="modal_msg">
                Do you want to delete this category
              </div>
              <div className="modal_btns ">
                <button className="purple" onClick={delete_service_category} data-bs-dismiss="modal" aria-label="Close" >Yes</button>
                <button data-bs-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;