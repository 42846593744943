import logo from './logo.svg';
import './App.css';
import './Mobile.css'
import React, { useState, useEffect } from 'react';
import Login_Signup from './pages/Login_Signup';
import Home from './pages/Home';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import Main from './pages/Main';

function App() {




  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{fontSize:"14px"}}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      <Main></Main>
    </>
  );
}

export default App;






