import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { toast } from 'react-toastify';
import { getRequest, postRequest } from '../ApiFunction';
import API from '../Api';
import { useNavigate } from 'react-router-dom';

export default function Terms() {
  const nav = useNavigate();
  const [id, setID] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const fetchData = async () => {
    try {
      const result = await getRequest(`${API.GET_TERMS}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear();
          nav('/login');
          window.location.reload();
        } else {
          toast.error(result.data.message);
        }
      } else {
        setID(result.data.results._id);
        const termsHTML = result.data.results.terms;

        // Convert HTML to Draft.js content state
        const contentBlock = htmlToDraft(termsHTML);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorStateWithContent = EditorState.createWithContent(contentState);
        setEditorState(editorStateWithContent);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const contentHTML = draftToHtml(rawContentState);

      const result = await postRequest(`${API.UPDATE_TERMS}`, { termsId: id, data: contentHTML });

      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear();
          nav('/login');
          window.location.reload();
        } else {
          toast.error(result.data.message);
        }
      } else {
        toast.success(result.data.message);
        fetchData(); // Reload the data to reflect changes
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="top_bar row align-items-center mt-2 mb-2">
        <div className="col-lg-12">
          <div className="pagetitle">Terms and Conditions</div>
        </div>
      </div>
      <div className="outer_div mt-4 content">
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <div className="purple_btn mt-4">
        <button className="btns" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
}
