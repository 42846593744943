// const BASE_URL = 'http://localhost:3850/admin/add/'
// const ImageUrl = "http://localhost:3850/uploads/"

// const BASE_URL = 'http://3.225.237.130:3850/admin/add/'
// const ImageUrl = "http://3.225.237.130:3850/uploads/"


const BASE_URL = 'https://api.makeme.media/admin/add/'
const ImageUrl = "https://api.makeme.media/uploads/"




const routes = {
    //ADMIN SECTION
    GET_ADMIN_PROFILE: BASE_URL + 'getAdmin',
    UPDATE_ADMIN_PROFILE: BASE_URL + 'updateAdmin',
    LOGIN_ADMIN: BASE_URL + 'login',
    CHANGE_PASSWORD: BASE_URL + 'changePasswords',
    GET_NOTIFICATION: BASE_URL + 'get_notification',
    GET_DASHBOARD: BASE_URL + 'dashboard',
    LOGOUT: BASE_URL + 'logout',
    FORGOT_PASSWORD: BASE_URL + 'forgotPassword',
    VERIFY_OTP: BASE_URL + 'verifyOtp',
    SET_NEW_PASSWORD: BASE_URL + 'newPassword',



    //CUSTOMER SECTION
    GET_ALL_CUSTOMER: BASE_URL + 'getUsersCustomer',
    BLOCK_CUSTOMER: BASE_URL + 'adminblock',
    DELETE_CUSTOMER_USER: BASE_URL + 'deleteUser',
    GET_CUSTOMER_DETAILS: BASE_URL + 'get_customer_details',
    EDIT_CUSTOMER: BASE_URL + 'update_customer',


    //PROVIDER SECTION
    GET_ALL_PROVIDER: BASE_URL + 'getuserProvider',
    ADMIN_VERIFY_PROVIDER: BASE_URL + 'adminVerify',
    ADMIN_REJECT_PROVIDER: BASE_URL + 'admin_un_Verify',
    GET_PROVIDER_DETAILS: BASE_URL + 'get_provider_details',


    //CATEGORY SECTION
    CREATE_CATEGORY: BASE_URL + 'service_category',
    GET_ALL_CATEGORY: BASE_URL + 'get_service_category',
    EDIT_SERVICE_CATEGORY: BASE_URL + 'edit_service_category',
    DELETE_SERVICE_CATEGORY: BASE_URL + 'remove_service_category',


    //SERVICE RELATED
    GET_BOOKING_SERVICES: BASE_URL + 'get_booking_request',
    GET_SERVICE_DETAILS: BASE_URL + 'get_service_details',


    //PAYMENT SECTION
    GET_ALL_PAYMENTS: BASE_URL + 'get_payment',
    GET_PAYMENT_DETAILS: BASE_URL + 'get_payment_details',

    //RATING SECTION
    GET_ALL_RATINGS: BASE_URL + 'get_rating',
    DELETE_RATING: BASE_URL + 'delete_rating',
    BLOCK_PROVIDER_RATING: BASE_URL + 'adminblock',


    //ANNOUNCEMENT SECTION
    SEND_ANNOUNCEMENT: BASE_URL + 'send_announcement',
    GET_CUSTOMER_TYPE: BASE_URL + 'get_customer_provider',
    GET_ALL_ANNOUNCEMENT_HISTORY: BASE_URL + 'get_all_announcement',
    ARCHIVED_UN_ARCHIVED: BASE_URL + 'archived_un_archived_announcement',



    //CONTENT SECTION
    GET_PRIVACY: BASE_URL + 'getPrivacy',
    UPDATE_PRIVACY: BASE_URL + 'updatePolicy',
    GET_TERMS: BASE_URL + 'getTerms',
    UPDATE_TERMS: BASE_URL + 'updateTerms',
    GET_DISCLAIMER: BASE_URL + 'getDisClaimer',
    UPDATE_DISCLAIMER: BASE_URL + 'updateDisclaimer',

    //REGION SECTION
    CREATE_REGION: BASE_URL + 'create_region',
    EDIT_REGION: BASE_URL + 'edit_region',
    DELETE_REGION: BASE_URL + 'delete_region',
    GET_ALL_REGION: BASE_URL + 'get_all_region',
    DELETE_REGION_PER: BASE_URL + 'delete_region_per',

    //ID_TYPES SECTION
    CREATE_ID: BASE_URL + 'create_new_id',
    EDIT_ID: BASE_URL + 'edit_id_type',
    GET_ALL_ID: BASE_URL + 'get_id_types',

    // PERCENTAGE_VALUE
    EDIT_PERCENTAGE_VALUE: BASE_URL + 'edit_percentage_value',
    GET_PERCENTAGE_VALUE: BASE_URL + 'get_percentage_value',

    //PLATFORM_FEE
    EDIT_PLATFORM_FEE: BASE_URL + 'edit_platform_fee',
    GET_PLATFORM_FEE: BASE_URL + 'get_platform_fee',

    //ADDRESS SECTION
    GET_ADDRESS: BASE_URL + 'getUsersAddresses',
    GET_ADDRESS_DETAILS: BASE_URL + 'getAddressDetails',
    VERIFY_ADDRESS: BASE_URL + 'verifyAddress',
    DELETE_ADDRESS: BASE_URL + 'deleteAddress'
}

export default routes;
export { BASE_URL, ImageUrl }