import React, { useEffect, useState } from "react";
import Edit_button from "../common/Edit_button";
import { Back, EditICON, ChangeImage } from "../common/Icons";
import Service_card from "../common/Service_card";
import {
  NavLink,
  useLocation,
  useNavigation,
  useParams,
} from "react-router-dom";
import axios from "axios";
import server from "../server";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { getRequest, postRequest } from "../ApiFunction";
import API, { BASE_URL, ImageUrl } from "../Api"
import Loader from "../common/Loader";

export default function Customer_details() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const userId = useParams();
  const [load, setLoad] = useState(true);
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [showImage, setShowImage] = useState("");
  const [file, setFile] = useState("");
  const [detailObj, setDetailObj] = useState({
    full_name: "",
    email: "",
  });

  const getData = async () => {
    try {
      const result = await getRequest(`${API.GET_CUSTOMER_DETAILS}?userId=${userId.id}`)
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        setDetailObj(result.data.customer_details)
        setData(result.data.service_request)
        setLoad(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const updateUser = async () => {
    try {
      if (detailObj.name === "" || detailObj.email === "") {
        toast.error("Name & Email not allowed to be empty!");
      } else {
        const formData = new FormData();
        formData.append("full_name", detailObj.full_name);
        formData.append("email", detailObj.email);
        formData.append("file", showImage !== "" ? file : detailObj.profile_pic);
        formData.append("userId", location.state.id);
        const result = await postRequest(`${API.EDIT_CUSTOMER}`, formData)
        if (!result.data.status) {
          if (result.data.code === 203) {
            localStorage.clear()
            nav('/login')
            window.location.reload()
        }else{
            toast.error(result.data.message)
        }
        } else {
          getData()
          toast.success(result.data.message)
          setEdit(false);
          setShowImage("")
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Something went wrong");
    }
  };

  const addData = (e) => {
    setDetailObj({
      ...detailObj,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleImage = (e) => {
    setShowImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const getServiceStatusColor = (status) => {
    switch (status) {
      case 0:
      case 1:
      case 3:
      case 4:
        return "orange";
      case 2:
      case 6:
        return "red";
      case 5:
        return "green";
      default:
        return "";
    }
  };

  const getServiceStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Pending";
      case 2:
        return "Cancelled";
      case 3:
        return "Pending";
      case 4:
        return "Pending";
      case 5:
        return "Completed";
      case 6:
        return "Cancelled";
      default:
        return ""; // Default label, you can change it to another label or remove it
    }
  };

  const formatBookingTime = (time) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedTime = new Date(time).toLocaleDateString("en-US", options);
    const timeString = new Date(time).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    return `${formattedTime.replace(/,/g, "")} at ${timeString}`;
  };


  useEffect(() => {
    getData();
    window.scroll(0, 0)
  }, [userId.id !== ""]);



  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <NavLink to={"/customer-management"}>
            <div className="pagetitle">
              <span>  <Back />  </span>  Customer details
            </div>
          </NavLink>
        </div>
        <div className="col-lg-6 text-end">
          {edit ? null : (
            <div onClick={handleEdit}>
              <EditICON />
              Edit
            </div>
          )}
        </div>
      </div>
      {load
        ?
        <Loader /> :
        <>
          <div className="outer_div mt-4">
            <div className="row border-bottom">
              <div className="col-lg-2 col-md-2">
                <div className="profile_image">
                  {edit ?
                    <>
                      {showImage !== "" ?
                        <>
                          <label htmlFor="profilePic">
                            <div className="imageSet">
                              <img src={showImage} alt="profileImage" className="customerProfilePic" />
                              <div className="imageChnage" style={{ top: "71px" }}>
                                <ChangeImage />
                              </div>
                            </div>
                          </label>
                          <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }} accept="image/*" />
                        </>
                        :
                        <>
                          <label htmlFor="profilePic">
                            <div className="imageSet">
                              {detailObj.profile_pic !== "" ? (
                                <img src={ImageUrl + detailObj.profile_pic} alt="profileImage" className="customerProfilePic" />
                              ) : (
                                <img src="/images/blank_pic.png" alt="profileImage" className="customerProfilePic" />
                              )}
                              <div className="imageChnage" style={{ top: "71px" }}>
                                <ChangeImage />
                              </div>
                            </div>
                          </label>
                          <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }} accept="image/*" />
                        </>}
                    </>
                    :
                    <>
                      {detailObj.profile_pic !== "" ? (
                        <img src={ImageUrl + detailObj.profile_pic} alt="profileImage" className="customerProfilePic" />
                      ) : (
                        <img src="/images/blank_pic.png" alt="profileImage" className="customerProfilePic" />
                      )}
                    </>}

                  {/* {edit ? (
                <>
                  {showImage === "" ? (
                    <>
                    
                      />
                    </>
                  ) : (
                    <>
                      <label htmlFor="profilePic">
                        <div className="imageSet">
                          <img src={showImage} alt="profileImage" className="customerProfilePic" />
                          <div className="imageChnage" style={{ top: "71px" }}>
                            <ChangeImage />
                          </div>
                        </div>
                      </label>
                      <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }} accept="image/*" />
                    </>
                  )}
                </>
              ) : (
                <>
                  {detailObj.profile_pic !== "" ? (
                    <img src={server.ImageUrl + detailObj.profile_pic} alt="profileImage" className="customerProfilePic" />
                  ) : (
                    <img src="/images/blank_pic.png" alt="profileImage" className="customerProfilePic" />
                  )}
                </>
              )}
                <label htmlFor="profilePic">
                        <div className="imageSet">
                          {detailObj.profile_pic !== "" ? (
                            <img src={server.ImageUrl + detailObj.profile_pic} alt="profileImage" className="customerProfilePic" />
                          ) : (
                            <img src="/images/blank_pic.png" alt="profileImage" className="customerProfilePic" />
                          )}
                          <div className="imageChnage" style={{ top: "71px" }}>
                            <ChangeImage />
                          </div>
                        </div>
                      </label>
                      <input id="profilePic" type="file" onChange={handleImage} style={{ display: "none" }}  accept="image/*"/> */}
                </div>
              </div>
              <div className="col-lg-10 col-md-10">
                <ul className="profile_list p-0">
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Full Name</div>

                      {!edit ? (
                        <div className="name mt-2">{detailObj.full_name}</div>
                      ) : (
                        <input type="text" placeholder="Enter you name..." value={detailObj.full_name} name="full_name"
                          id="full_name" onChange={addData} />
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Email Address</div>
                      {!edit ? (
                        <div className="name mt-2">{detailObj.email}</div>
                      ) : (
                        <input type="text" placeholder="Enter you email..." value={detailObj.email} name="email" id="email" onChange={addData} />
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">joining date</div>
                      <div className="name mt-2">
                        {moment(detailObj.timestamp).format('LLL')}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {edit ? (
              <div
                className="modal_btns"
                style={{ justifyContent: "flex-start" }}
                onClick={updateUser}
              >
                <button className="purple">Save</button>
              </div>
            ) : null}
            <div className="card_botom">
              <div className="sub_title mt-4">{data && data.length > 0 ? 'Services Request' : null}</div>
              <div className="row">
                {data.map((service, index) => (
                  <div className="col-lg-6 mt-4" key={index}>
                    <div className="card_outer">
                      <div className={"service_status"} style={{ color: getServiceStatusColor(service.status) }}>
                        {getServiceStatusLabel(service.status)}
                      </div>
                      <div className="card_column">
                        <div className="left_col">
                          <div className="name">{service.provider_name}</div>
                          <div className="small_heading">
                            {moment(service.time_of_booking).format('LLL').slice(0, 16)} At {moment(service.time_of_booking).format('LLL').slice(16, 40)}
                          </div>
                          <div className="small_heading">
                            Services:{service.service_selected[0]}
                          </div>
                        </div>
                        <div className="right_col">
                          <div className="service_name">
                            {" "}
                            SAR {service.total_price}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>}
    </>
  );
}
