import React, { useEffect, useState } from "react";
import Edit_button from "../common/Edit_button";
import {
  Back,
  Search,
  Star,
  HalfStar,
  EditICON,
  ChangeImage,
} from "../common/Icons";
import Service_card from "../common/Service_card";
import Search_input from "../common/Search_input";
import Doc_images from "../common/Doc_images";
import Rating from "../common/Rating";
import Testimonial from "../common/Testimonial";
import { NavLink } from "react-router-dom";
import axios from "axios";
import server from "../server";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../ApiFunction";
import API, { ImageUrl } from "../Api"
import Loader from "../common/Loader";
import moment from "moment";


export default function Provider_details() {
  const [load, setLoad] = useState(true)
  const userId = useParams();
  const nav = useNavigate()
  const [data, setData] = useState({})
  const [search, setSearch] = useState("");
  const [testimonial, setTestimonial] = useState([]);
  const [services, setServices] = useState([])
  const [search_rating, setSearchRating] = useState('')
  const [certificate, setCertificate] = useState('')
  const getData = async () => {
    try {
      const result = await getRequest(`${API.GET_PROVIDER_DETAILS}?userId=${userId.id}&search=${search}&search_rating=${search_rating}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          toast.error(result.data.message)
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        setLoad(false);
        setData(result.data.availability);
        setTestimonial(result.data.testimonial)
        setServices(result.data.service_request)
      }
    } catch (error) {
      console.log(error.message)
    }
  };




  const generateStarCounts = () => {
    const starCounts = Array.from({ length: 5 }, (_, index) => {
      const starRating = index + 1;
      const foundItem = data.rating_count_by_number && data.rating_count_by_number.find((item) => item._id === starRating);
      return foundItem
        ? { id: foundItem._id, percentage: foundItem.percentage }
        : { id: starRating, percentage: 0 };
    });
    return starCounts.sort((a, b) => b.id - a.id);
  };

  const generateStars = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const starComponents = [];
    for (let i = 1; i <= filledStars; i++) {
      starComponents.push(<Star key={i} />);
    }
    if (hasHalfStar) {
      starComponents.push(<HalfStar key="half" />);
    }
    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);
    for (let i = 1; i <= emptyStars; i++) {
      starComponents.push(<Star key={`empty-${i}`} />);
    }
    return starComponents;
  };



  const searchType = async (e) => {
    setSearch(e.target.value);
  };

  const searchTestimonial = async (e) => {
    setSearchRating(e.target.value);
  };


  function formatIBAN(number) {
    if (!number) return "";
    return number.replace(/(.{4})/g, "$1 ");
  }

  function getDaysWithData(availability) {
    const daysWithData = [];

    for (const day in availability) {
      if (day !== "_id" && availability[day].length > 0) {
        daysWithData.push(day);
      }
    }
    return daysWithData.join(", ").replace(/, $/, "");
  }





  const admin_verify_User = async (id) => {
    try {
      const result = await postRequest(`${API.ADMIN_VERIFY_PROVIDER}`, { userId: id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        getData();
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  };

  const admin_un_verify_User = async (id) => {
    try {
      const result = await postRequest(`${API.ADMIN_REJECT_PROVIDER}`, { userId: id })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
        } else {
          toast.error(result.data.message)
        }
      } else {
        nav('/provider-management')
        toast.success(result.data.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  };



  useEffect(() => {
    getData();
  }, [search]);



  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <div className="pagetitle">
            <NavLink to={"/provider-management"}>
              <div className="d-flex align-items-center">
                <span>
                  <Back />
                </span>
                Provider details
              </div>
            </NavLink>
          </div>
        </div>
        <div className="col-lg-6 text-end">
        </div>
      </div>
      {load ? <Loader /> :
        <>
          <div className="outer_div mt-4">
            <div className="row border-bottom">
              <div className="col-lg-2 col-md-2">
                <div className="profile_image">
                  {data.profile_pic !== "" ? (
                    <img src={ImageUrl + data.profile_pic} alt="profileImage" className="customerProfilePic" />
                  ) : (
                    <img src="/images/blank_pic.png" alt="profileImage" className="customerProfilePic" />
                  )}
                </div>
              </div>
              <div className="col-lg-10 col-md-10">
                <ul className=" p-0 two_column">
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Full Name</div>
                      <div className="name mt-2">{data.full_name}</div>
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Email Address</div>
                      <div className="name mt-2">
                        {" "}
                        {data.email}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Total Experience</div>
                      <div className="name mt-2">
                        {data.experience}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Timing</div>
                      <div className="name mt-2">
                        {getDaysWithData(data.availability)}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Joining date</div>
                      <div className="name mt-2">
                        {
                          moment(data.timestamp).format('DD-MM-YYYY')}
                      </div>
                    </div>
                  </li>
                  {data.verifiedByAdmin ? 'Verified' : <li>
                    <div className="d-flex gap-2 modal_btns">
                      <button className="purple" style={{ backgroundColor: "green" }} onClick={() => admin_verify_User(data._id)} >
                        Accept
                      </button>
                      <button className="purple" style={{ backgroundColor: "red" }} onClick={() => admin_un_verify_User(data._id)} >
                        Reject
                      </button>
                    </div>
                  </li>
                  }
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Regions</div>
                      {data.region &&
                        data.region.map((region) => (
                          <div key={region._id} className="name mt-2">
                            {region.region.address_name}, {region.region.city},{" "}
                            {region.region.state}, {region.region.country},{" "}
                            {region.region.postal_code}
                          </div>
                        ))}
                    </div>
                  </li>

                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Services Request</div>
                      {data.services_providing &&
                        data.services_providing.length > 0 ? (
                        <div className="name mt-2">
                          {data.services_providing.join(", ")}
                        </div>
                      ) : (
                        <div className="name mt-2">No services provided</div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">Description</div>
                      <div className="name mt-2">
                        {data.description}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="detail_wrapper">
                      <div className="small_heading">IBAN Number</div>
                      <div className="name mt-2">
                        {formatIBAN(data.iban_number)}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card_botom">
              <div className="row">
                <div className="col-lg-6">
                  <div className="sub_title">National ID & Certificate</div>
                  <div className="doc_gallery mt-4 mb-4">
                    {data.national_id && data.national_id.length > 0 ? (
                      <>
                        {
                          data.national_id.map((element, index) => (
                            <div className="doc_list text-center" key={index + 1}>
                              <img src={ImageUrl + element} alt="gallery" className="img-fluid w-100" />
                              <div className="small_heading">national id <button style={{ cursor: "pointer", textDecoration: "underline" }} data-bs-toggle="modal" data-bs-target='#view_certificate' onClick={() => { setCertificate(ImageUrl + element) }}>(view id)</button></div>
                            </div>
                          ))
                        }
                      </>
                    ) : (
                      <div className="small_heading">No data found.</div>
                    )}

                    {data.certificates && data.certificates.length > 0 ? (
                      <>
                        {
                          data.certificates.map((element, index) => (
                            <div className="doc_list text-center" key={index + 1}>
                              <img src={ImageUrl + element} alt="gallery" className="img-fluid w-100" />
                              <div className="small_heading">certificates <span style={{ cursor: "pointer", textDecoration: "underline" }} data-bs-toggle="modal" data-bs-target='#view_certificate' onClick={() => { setCertificate(ImageUrl + element) }}>(view certificate)</span></div>
                            </div>
                          ))
                        }
                      </>
                    ) : (
                      <div className="small_heading">No data found.</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sub_title">Gallery</div>
                  <div className="doc_gallery mt-4 mb-4">
                    {data.gallary && data.gallary[0] !== null ? (
                      <>
                        {
                          data.gallary.map((element, index) => (
                            <div className="doc_list text-center" key={index + 1}>
                              <img src={ImageUrl + element} alt="gallery" className="w-100" />
                            </div>
                          ))
                        }
                      </>
                    ) : (
                      <div className="small_heading">No data found.</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-4">
                  <div className="sub_title mb-4">Services request</div>
                  <Search_input
                    placeholder={"Search here"}
                    value={search}
                    onChange={searchType} // Ensure that the onChange event is connected.
                    icon={<Search />}
                    className="search_input"
                  />

                  <Service_card data={services} />
                </div>
                <div className="col-lg-6 mt-4">
                  <div className="sub_title mb-4">Testimonials</div>
                  <Search_input
                    placeholder={"Search here"}
                    value={search_rating}
                    onChange={searchTestimonial} // Ensure that the onChange event is connected.
                    icon={<Search />}
                    className="search_input"
                  />

                  <div className="rating_outer ">
                    <ul className="p-0 list-style-none">
                      <li>
                        <div className="num_point">
                          {data ? data.avg_rating || 0 : 0} <sub>/5</sub>
                        </div>
                        <div className="small_heading mt-2">
                          Based on {data && data.total_review} Reviews
                        </div>
                        <div className="stars mt-3" style={{ width: "90%" }}>
                          {generateStars(data ? data.avg_rating : 0)}
                        </div>
                      </li>
                      <li>
                        <ul className="rating_numbers">
                          {generateStarCounts().map(({ id, percentage }) => (
                            <li key={id}>
                              <div className="count_star">
                                {id} Star{" "}
                                <span
                                  className="strip"
                                  style={{ width: `${percentage}px` }}
                                ></span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="mt-4">
                    <Testimonial testimonial={testimonial} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="view_certificate" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content p-3">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                <div class="modal-body text-center">
                  <img src={certificate} alt="gallery" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </>}
    </>
  );
}
