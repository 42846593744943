import React, { useEffect, useState } from "react";
import Search_input from "../common/Search_input";
import { Delete, EditICON, Search } from "../common/Icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API, { ImageUrl } from "../Api"
import { getRequest, postRequest } from "../ApiFunction";

const IdTypes = () => {
    const [page, setPage] = React.useState(0);
    const [load, setLoad] = useState(true)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState("");
    const nav = useNavigate();
    const token = localStorage.getItem("token");
    const [totalDoc, setTotalDoc] = useState("");
    const [users, setUsers] = useState([]);
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState('')
    const [obj, setObj] = useState({ id_type: '' })

    const fetchData = async () => {
        try {
            const result = await getRequest(`${API.GET_ALL_ID}`);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                }else{
                    toast.error(result.data.message)
                }
            } else {
                setUsers(result.data.data);
                setLoad(false)
            }
        } catch (error) {
            console.log(error.message)
        }
    };


    const Create = async () => {
        try {
            if (obj.id_type === "") {
                toast.error("ID type is required!")
            } else {
                const result = await postRequest(`${API.CREATE_ID}`, obj)
                if (!result.data.status) {
                    if (result.data.code === 203) {
                        localStorage.clear()
                        nav('/login')
                        window.location.reload()
                    }else{
                        toast.error(result.data.message)
                    }
                } else {
                    fetchData()
                    toast.success(result.data.message)
                    setEdit(false)
                    setObj({ id_type: '' })
                }
            }
        } catch (error) {
            console.log(error.message)
        }
    }




    const addData = (e) => {
        setObj({
            ...obj,
            [e.target.name]: e.target.value,
        });
    };






    const updateCategory = async () => {
        try {
            const result = await postRequest(`${API.EDIT_ID}`, obj)
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    window.location.reload()
                }else{
                    toast.error(result.data.message)
                }
            } else {
                fetchData()
                toast.success(result.data.message)
                setObj({ id_type: '' })
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    };






    const editCategory = (user) => {
        setObj(user);
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                </div>
                <div className="col-lg-4 offset-lg-2">
                    <div className="editBtn"  style={{ width: "200px" }} data-bs-toggle="modal" data-bs-target="#create_category_modal"  >
                        Create ID Type
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className="table table-lg">
                        <thead className="table-light">
                            <tr colspan="4">
                                <th>S.No</th>
                                <th>ID Types</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user._id}>
                                    <td>{index + 1}</td>
                                    <td>{user.id_type}</td>
                                    <td>
                                        <div className="actions">
                                            <button className="edit" data-bs-toggle="modal" data-bs-target="#update_category_modal" onClick={() => editCategory(user)}  >
                                                <EditICON />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* edit category modal from here */}
            <div class="modal fade" id="update_category_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        <div class="modal-body text-center">
                            <div className="modal_msg">You can update this ID Type</div>
                            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                <input type="text" placeholder="id_type" name="id_type" id="id_type" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.id_type} onChange={addData} />
                            </div>
                            <div className="modal_btns ">
                                <button data-bs-dismiss="modal">Cancel</button>
                                <button className="purple" onClick={updateCategory} data-bs-dismiss="modal" aria-label="Close" >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* create category modal from here */}
            <div class="modal fade" id="create_category_modal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        <div class="modal-body text-center">
                            <div className="modal_msg">Create new id type from here</div>
                            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                                <input type="text" placeholder="new id type" name="id_type" id="id_type" style={{ marginBottom: "10px", marginTop: "10px" }} value={obj.id_type} onChange={addData} />
                            </div>
                            <div className="modal_btns ">
                                <button data-bs-dismiss="modal">Cancel</button>
                                <button className="purple" onClick={Create} data-bs-dismiss="modal" aria-label="Close" >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IdTypes;