import React, { useEffect, useState } from "react";
import { Block, Delete, Search, Star } from "../common/Icons";
import Tooltip from '@mui/material/Tooltip';
import Search_input from "../common/Search_input";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import API from "../Api"
import { getRequest, postRequest } from "../ApiFunction";
import Loader from "../common/Loader";

export default function Rating_management() {
  const nav = useNavigate();
  const [ratings, setRatings] = useState([]);
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState(true);
  const [ratingId, setRatingId] = useState(null);
  const [blockedId, setBlockedId] = useState(null);
  const [isBlocked, setIsBlocked] = useState(null);


  const setDeleteId = (id) => {
    setRatingId(id)
  }

  const setBlockId = (id, isBlocked) => {
    setBlockedId(id);
    setIsBlocked(isBlocked);
  };


  const getRatings = async () => {
    try {
      const result = await getRequest(`${API.GET_ALL_RATINGS}?search=${search}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        setRatings(result.data.data);
        setLoad(false);

      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const delete_Ratings = async (id) => {
    try {
      const result = await postRequest(`${API.DELETE_RATING}?ratingId=${id}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        getRatings()
        toast.success(result.data.message)
        setLoad(false);
        setRatingId(null)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const block = async (userId) => {
    try {
      const result = await postRequest(`${API.BLOCK_PROVIDER_RATING}?userId=${userId}`);
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        getRatings()
        toast.success(result.data.message)
        setLoad(false);
        setBlockedId(null)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }



  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<Star key={i} />);
    }
    return stars;
  };

  const searchType = async (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getRatings();
  }, [search]);

  return (
    <>
      <div className="top_bar row align-items-center">
        <div className="col-lg-6">
          <div className="pagetitle">Ratings management</div>
        </div>
        <div className="col-lg-5 offset-lg-1">
          <Search_input
            placeholder={"Search here"}
            icon={<Search />}
            className="search_input"
            value={search}
            onChange={searchType}
          />
        </div>
      </div>
      {load ? <Loader />
        :
        <>
          <div className="row mt-5">
            <div className="table-responsive">
              <table className="table table-lg">
                <thead className="table-light">
                  <tr colspan="6">
                    <th>Date</th>
                    <th>Booking ID</th>
                    <th>Provider name</th>
                    <th>Rating</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody>
                  {ratings.map((e) => {
                    return (
                      <tr>
                        <td>{moment(e.time_of_booking).format('DD-MM-YYYY')}</td>
                        <td>{e.bookingId}</td>
                        <td>{e.provider_name}</td>
                        <td>
                          <div className="table_rating">
                            <div className="rting_star d-flex align-items-center">
                              {renderStars(e.rating)}
                              <div className="small_heading">{e.rating}/5</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="actions">
                            <Tooltip title={e.isProvider_blocked ? "Unblock" : "Block"}>
                              <button
                                className={e.isProvider_blocked ? "blockgreen" : "block"}
                                data-bs-toggle="modal"
                                data-bs-target="#block_rating_modal"
                                onClick={() => setBlockId(e.providerId, e.isProvider_blocked)}
                              >
                                <Block />
                              </button>
                            </Tooltip>
                            <button
                              className="delete red"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_rating_modal_popup"
                              onClick={() => setDeleteId(e.rating_id)}
                            >
                              <Delete />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>}
      <div class="modal fade" id="delete_rating_modal_popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body text-center">
              <div className="modal_icon red">
                <Delete />
              </div>
              <div className="modal_msg">
                Do you want to delete this rating
              </div>
              <div className="modal_btns ">
                <button className='purple' onClick={() => { delete_Ratings(ratingId); }} data-bs-dismiss="modal">Yes</button>
                <button data-bs-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="block_rating_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body text-center">
              <div className="modal_icon green">
                <Block />
              </div>
              <div className="modal_msg">
                {isBlocked
                  ? "Do you want to unblock this user"
                  : "Do you want to block this user"}
              </div>
              <div className="modal_btns ">
                <button className='purple' onClick={() => { block(blockedId) }} data-bs-dismiss="modal">Yes</button>
                <button data-bs-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
