import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import server from '../server';
import axios from 'axios';
import { toast } from 'react-toastify';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { getRequest, postRequest } from '../ApiFunction';
import API from "../Api"
import { useNavigate } from 'react-router-dom';

export default function Disclaimer() {
  const nav = useNavigate()
  const [id, setID] = useState('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const fetchData = async () => {
    try {
      const result = await getRequest(`${API.GET_DISCLAIMER}`)
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        setID(result.data.results._id)
        const termsHTML = result.data.results.disclamer;
        const contentBlock = convertFromHTML(termsHTML);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorStateWithContent = EditorState.createWithContent(contentState);
        setEditorState(editorStateWithContent);
      }
    } catch (error) {
      console.log(error.message)
    }
  };



  const handleSubmit = async () => {
    try {
      const termsPlainText = editorState.getCurrentContent().getPlainText();
      const result = await postRequest(`${API.UPDATE_DISCLAIMER}`, { disclamerId: id, data: termsPlainText })
      if (!result.data.status) {
        if (result.data.code === 203) {
          localStorage.clear()
          nav('/login')
          toast.error(result.data.message)
          window.location.reload()
      }else{
          toast.error(result.data.message)
      }
      } else {
        toast.success(result.data.message)
        fetchData()
      }
    } catch (error) {
      console.log(error.message)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="top_bar row align-items-center mt-2 mb-2">
        <div className="col-lg-12">
          <div className="pagetitle">Disclaimer</div>
        </div>
      </div>
      <div className="outer_div mt-4 content">
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <div className="purple_btn mt-4">
        <button className="btns" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
}


