import React from "react";

export default function Service_card({ data }) {
  const getServiceStatusColor = (status) => {
    switch (status) {
      case 0:
      case 1:
      case 3:
      case 4:
        return "orange";
      case 2:
      case 6:
        return "red";
      case 5:
        return "green";
      default:
        return "";
    }
  };

  const getServiceStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Pending";
      case 2:
        return "Cancelled";
      case 3:
        return "Pending";
      case 4:
        return "Pending";
      case 5:
        return "Completed";
      case 6:
        return "Cancelled";
      default:
        return ""; // Default label, you can change it to another label or remove it
    }
  };

  function formatDate(timestamp) {
    if (!timestamp) return ""; // Handle the case when timestamp is not available

    const date = new Date(timestamp); // No need to multiply by 1000 for milliseconds
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  }

  return (
    <>
      <div className="col-lg-6 mt-4 w-100">
        {data.map((service, index) => (
            <div className="card_outer" key={index}>
              <div
                className={`service_status ${getServiceStatusColor(
                  service.status
                )}`}
              >
                {getServiceStatusLabel(service.status)}
              </div>
              <div className="card_column">
                <div className="left_col">
                  <div className="name">{service.provider_name}</div>
                  <div className="small_heading">{formatDate(service.time_of_booking)}</div>
                  <div className="small_heading">
                    {service.service_selected.join(", ")}
                  </div>
                </div>
                <div className="right_col">
                  <div className="service_name">SAR {service.total_price}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* <div className="card_outer">
                <div className={"service_status " + ClassName}>{Service_status}</div>
                <div className="card_column">
                    <div className="left_col">
                        <div className="name">{Name}</div>
                        <div className="small_heading">{Date_time}</div>
                        <div className="small_heading">{Service_title}</div>
                    </div>
                    <div className="right_col">
                        <div className=" service_name">{Service_price}</div>
                    </div>
                </div>
            </div> */}
    </>
  );
}
